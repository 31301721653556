import React, { useState , useEffect } from "react";
import { TbRosetteDiscountCheckFilled } from "react-icons/tb";
import "../Style/Pricing.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { motion } from "framer-motion";

import AnimatedSection from "../Components/AnimatedSection";
import CreditsFAQ from "../Components/CreditsFAQ";
import PricingTableMobileRecharge from "../Components/PricingTableMobileRecharge";
import Testimonial from "../Components/Testimonial";
import { AuthFetch } from "../lib/auth";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const settings = {
   centerMode: true,
   centerPadding: "30",
   slidesToShow: 1,
   dots: true,
   infinite: true,
   speed: 500,
   arrows: false,
   focusOnSelect: true,
   autoplay: true,
   autoplaySpeed: 5000, // Slide change interval in milliseconds
   responsive: [
      {
         breakpoint: 768,
         settings: {
            slidesToShow: 1,
            centerMode: false,
         },
      },
      {
         breakpoint: 1024,
         settings: {
            slidesToShow: 1,
            centerMode: false,
         },
      },
   ],
};

const accordionData = [
   {
      title: "Is there a free trial available?",
      description:
         "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
   },
   {
      title: "Can I change my plan later?",
      description: `Absolutely, you can easily upgrade or downgrade your plan at any time to better suit your evolving needs. Just log in to your account and navigate to the settings to make the necessary changes.`,
   },
   {
      title: "What is your cancellation policy?",
      description: `We understand that circumstances may change, so we have a hassle-free cancellation policy. You can cancel your subscription at any time, and you will not be charged any further unless you reactivate your account.`,
   },
   {
      title: "Can other info be added to an invoice?",
      description: `Certainly, you can customize your invoices by adding additional information such as purchase order numbers, project details, or any other relevant data. Our system allows for flexibility to accommodate your specific requirements.`,
   },
   {
      title: "How does billing work?",
      description: `Our billing operates on a [monthly/yearly] subscription basis. You will be billed automatically at the beginning of each billing cycle. You can view your billing history and upcoming charges in your account settings.`,
   },
   {
      title: "How do I change my account email?",
      description: `To change your account email, simply log in to your account and navigate to the account settings. You will find the option to edit your email address there. Once you've made the necessary changes, make sure to verify your new email to ensure the security of your account.`,
   },
];
const daysLeft = () => {
   const today = new Date();
   const currentYear = today.getFullYear();
   const feb1 = today.getMonth() === 1 && today.getDate() > 1
       ? new Date(currentYear + 1, 1, 1) // February 1 of next year if today is past February
       : new Date(currentYear, 1, 1); // February 1 of the current year
   const diffInTime = feb1 - today; // Difference in milliseconds
   return Math.ceil(diffInTime / (1000 * 60 * 60 * 24)); // Convert to days
};

const Recharge = () => {
   const [loading, setLoading] = useState(false);
   const isAuthenticated = useAuth();
   const navigate = useNavigate()
      const [daysLeftUntilJan, setDaysLeftUntilJan] = React.useState(daysLeft());

   const createCheckoutSession = async (priceId) => {
      setLoading(true)
      if (!isAuthenticated) {
         navigate('/login')
      }
      console.log("ENV var for 2500:", process.env.REACT_APP_PRICE_ID_2500);
      console.log("Price ID being sent to backend:", priceId);
      const { session } = await AuthFetch(`stripe/checkoutSession/${priceId}`, {
         method: 'GET'
      });
      window.location.href = session;   
   }
   useEffect(() => {
  document.title = "Recharge PPC Credits - No Subscription Required | PPCC.ai";

  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute("content", "Purchase PPC credits on demand. No monthly commitments. Pay only for what you use with PPCC.ai and keep your campaigns running smoothly.");
  }

  const metaKeywords = document.querySelector('meta[name="keywords"]');
  if (metaKeywords) {
    metaKeywords.setAttribute("content", "PPC credits, pay-as-you-go PPC, no subscription PPC, flexible PPC pricing, PPC recharge, PPCC.ai");
  }

  // Open Graph tags
  const ogTitle = document.querySelector('meta[property="og:title"]');
  if (ogTitle) {
    ogTitle.setAttribute("content", "Recharge PPC Credits - No Subscription Required | PPCC.ai");
  }

  const ogDescription = document.querySelector('meta[property="og:description"]');
  if (ogDescription) {
    ogDescription.setAttribute("content", "Purchase PPC credits on demand. No monthly commitments. Pay only for what you use with PPCC.ai and keep your campaigns running smoothly.");
  }

  const ogImage = document.querySelector('meta[property="og:image"]');
  if (ogImage) {
    ogImage.setAttribute("content", "URL_to_recharge_image");
  }

  const ogUrl = document.querySelector('meta[property="og:url"]');
  if (ogUrl) {
    ogUrl.setAttribute("content", "https://www.ppcc.ai/recharge");
  }

  // Twitter Card tags
  const twitterTitle = document.querySelector('meta[name="twitter:title"]');
  if (twitterTitle) {
    twitterTitle.setAttribute("content", "Recharge PPC Credits - No Subscription Required | PPCC.ai");
  }

  const twitterDescription = document.querySelector('meta[name="twitter:description"]');
  if (twitterDescription) {
    twitterDescription.setAttribute("content", "Top up your PPC credits anytime. No subscriptions—just a simple pay-as-you-go model to keep your campaigns running.");
  }

  const twitterImage = document.querySelector('meta[name="twitter:image"]');
  if (twitterImage) {
    twitterImage.setAttribute("content", "URL_to_recharge_image");
  }

  const twitterCard = document.querySelector('meta[name="twitter:card"]');
  if (twitterCard) {
    twitterCard.setAttribute("content", "summary_large_image");
  }

}, []);

   return (
      <div classNamxe="main">
            <section className="table-sc Pricing-sec text-center space-mr-m ">
               <div className="container">
                  <AnimatedSection timingFunction="ease-out" delay={0.1}><div className="top-title mb-3 pb-2">CREDITS</div></AnimatedSection>
                  <AnimatedSection timingFunction="ease-out" delay={0.4}><h1 className="h1 pricingheadtitle">Pay as you go. Recharge here.</h1></AnimatedSection>
                  <AnimatedSection timingFunction="ease-out" delay={0.5}><div className="desc mb-4 mt-3 pricingdesc">Same features, no monthly payments.</div></AnimatedSection>
               </div>

               <div className="container container2">
                  <AnimatedSection><div className="transparent-table transparent-table2 position-relative d-lg-block d-none">
                     <div class="table-inner position-relative overflow-hidden">
                        <table class="w-100">
                           <thead>
                              <tr>
                              <th>
                                    <div className="d-lg-flex f-row">
                                       <h3 class="tabletitle">Subscribe</h3>{" "}
                                       <div className="off-40">
                                          Save 40%
                                       </div>
                                    </div>

                                    <p class="p-16">Credits at nearly half the price, upgrade your plan <a href="/pricing" class="linknostyle">here</a>.</p>
                                 </th>
                                 <th>
                                    <div className="amount">
                                       <span class="pricingamount">$0.02</span>/Credit
                                    </div>

                                    <button onClick={()=>{createCheckoutSession(process.env.REACT_APP_PRICE_ID_2500)}} className="btn btn-sq">
                                       Unlock Credits
                                    </button>
                                 </th>
                                 <th>
                                    <div className="amount">
                                       <span class="pricingamount">$0.015</span>/Credit
                                    </div>

                                    <button onClick={()=>{createCheckoutSession(process.env.REACT_APP_PRICE_ID_15000)}} href="" className="btn btn-sq w-100">
                                    Unlock Credits
                                    </button>
                                 </th>
                                 <th>
                                    <div className="amount">
                                       <span class="pricingamount">$0.01</span>/Credit
                                    </div>

                                    <button onClick={()=>{createCheckoutSession(process.env.REACT_APP_PRICE_ID_45000)}} href="" className="btn btn-sq w-100">
                                    Unlock Credits
                                    </button>
                                 </th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr>
                                 <td class="tabletitle">Amount of Credits</td>
                                 <td>2,500 Credits</td>
                                 <td>15,000 Credits</td>
                                 <td>45,000 Credits</td>
                              </tr>
                              <tr>
                                 <td class="tabletitle">Equivalent of</td>
                                 <td>10 Campaigns</td>
                                 <td>60 Campaigns</td>
                                 <td>180 Campaigns</td>
                              </tr>
                              
                           </tbody>
                        </table>
                        <motion.div
   className="gradient d-xl-block d-none"
   initial={{ left: "-10%", opacity: 0 }}
   animate={{ left: ["-10%", "90%"], opacity: [1, 1, 0] }}
   transition={{
      duration: 5.2, // Duration of the light animation
      ease: [0.2, 0.4, 0.8, 1], // Custom cubic-bezier for fast start and slow end
      repeat: Infinity,
      repeatDelay: 2,
      times: [0, 0.7, 1],
   }}
/>
<motion.div
   className="gradient-bottom d-xl-block d-none"
   initial={{ top: "-10%", opacity: 0 }}
   animate={{ top: ["-10%", "90%"], opacity: [1, 1, 0] }}
   transition={{
      duration: 4.7, // Duration of the light animation
      ease: [0.2, 0.4, 0.8, 1], // Custom cubic-bezier for fast start and slow end
      repeat: Infinity,
      repeatDelay: 2.5,
      times: [0.3, 0.7, 0.8],
   }}
/>
                     </div>
                  </div>
   </AnimatedSection>
                  <div className="d-block d-lg-none">
                     <PricingTableMobileRecharge />
                  </div>
               </div>
            </section>
         <CreditsFAQ />
         
      </div>
   );
};

export default Recharge;
