import axios from "axios";
import { motion, useSpring, useTransform, useViewportScroll } from "framer-motion";
import React, { useState, useEffect, useRef } from "react";
import AnimatedSection from "../Components/AnimatedSection";
// import { Modal, Button } from 'react-bootstrap';
import Loader from "../Components/Loader";
import BackdropAnimation from "../Components/BackdropAnimation";
import BackdropGlow from "../Components/BackdropGlow";
import HeightAnimatedSection from "../Components/HeightAnimatedSection";
import MoreSetting from "../Components/MoreSetting";
import useAuth from "../hooks/useAuth";
import { AuthFetch, AuthFetch2, refresh, signUpWithGoogle, signUpWithMicrosoft } from "../lib/auth";
import { useNavigate } from "react-router-dom";
import { stagger, useAnimate } from "framer-motion";
import CornerWrapAnimation from "../Components/CornerWrapAnimation";


const Generated = () => {
    const isAuthenticated = useAuth();
    const navigate = useNavigate()
    const Mocksite = [
       {
          site_text: "Try for Yourself",
          site_url:
             "#",
          sitePara: "Generate a PPC ad for your website now.",
       },
       {
          site_text: "It's Completely Free",
          site_url:
             "#",
          sitePara: "See how AI makes ad creation effortless.",
       },
       {
          site_text: "Your Page here",
          site_url:
             "#",
          sitePara: "Let's customize your journey with us.",
       },
       {
          site_text: "Launch in 5 Minutes",
          site_url:
             "#",
          sitePara: "Try for yourself by entering a URL above.",
       },
    ];
    const { scrollY } = useViewportScroll();
    const scale = useTransform(scrollY, [0, 1000], [1, 0.9]);
    const smoothScale = useSpring(scale, { stiffness: 50, damping: 10 });
    const [showModal, setShowModal] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const handleClose = () => setShowModal(false);
    const labels = document.querySelectorAll('.label-sc');
    labels.forEach((label) => {
       label.addEventListener('click', () => {
          labels.forEach((lbl) => lbl.classList.remove('checked'));
          label.classList.add('checked');
       });
    });
    
    const [showCustomInstructions, setShowCustomInstructions] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
       const timer = setTimeout(() => setIsVisible(true), 1400);
       return () => clearTimeout(timer); // Clean up the timer
    }, []);
    
    const [instructions, setInstructions] = useState(""); // State to hold the textarea value
    const maxInstructionsLength = 200; // Maximum character limit
    
    const handleInstructionsChange = (e) => {
       const value = e.target.value.trim();
       const field = selectedMenu.toLowerCase();
    
       if (value.length <= maxInstructionsLength) {
          setInstructions(value);
    
          setCampaignData((prev) => ({
             ...prev,
             [campaignMode]: {
                ...prev[campaignMode],
                menuItems: {
                   ...prev[campaignMode].menuItems,
                   [selectedMenu]: value === "" ? null : value,
                },
             },
          }));
    
          setCheckedItems((prev) => ({
             ...prev,
             [field]: value !== "",
          }));
       }
    };
    
    const [checkedItems, setCheckedItems] = useState({
       all: true,
       headline: true,
       descriptions: true,
       sitelinks: true,
       callout: true,
       snippets: true,
       sheetURL: true,
    });
    const [numberOfAds, setNumberOfAds] = useState(1);
    const [numberOfKeywords, setNumberOfKeywords] = useState(5);
    const [totalCost, setTotalCost] = useState(250);
    const [campaignMode, setCampaignMode] = useState("Broad search campaign");
    const [selectedMenu, setSelectedMenu] = useState("Headlines");
    
    const [campaignData, setCampaignData] = useState({
       "Brand name campaign": {
          menuItems: {
             Headlines: `- Capitalize for readability.\n- Engaging, to the point.\n- Aim for CTR and CTA.\n- 30 characters max.`,
             Descriptions: `- Capitalize for readability.\n- Clear, concise, actionable.\n- Focus on product value.\n- 90 characters max.`,
             Sitelinks: `- Capitalize for readability.\n- Name matches page intent.\n- Descriptions: 7-12 words max.\n- Provide clear final URLs.`,
             Snippets: `- Select predefined headers.\n- Each header: 4 relevant items.\n- Align with ad content.\n- Focus on clarity and engagement.`,
             Callouts: `- Capitalize for readability.\n- Highlight unique selling points.\n- Keep it concise, 5-20 characters.\n- Encourage clicks with impact.`,
             Keywords: "- Medium long-tail: 3-4 words each.\n- 1 keyword of 3 words and 2 of 4 words.\n- No hyphens & no consecutive long words.\n- Maximum 5 words per keyword."
          },
       },
       "Broad search campaign": {
          menuItems: {
             Headlines: `- Capitalize for readability.\n- Engaging, to the point.\n- Aim for CTR and CTA.\n- 30 characters max.`,
             Descriptions: `- Capitalize for readability.\n- Clear, concise, actionable.\n- Focus on product value.\n- 90 characters max.`,
             Sitelinks: `- Capitalize for readability.\n- Name matches page intent.\n- Descriptions: 7-12 words max.\n- Provide clear final URLs.`,
             Snippets: `- Select predefined headers.\n- Each header: 4 relevant items.\n- Align with ad content.\n- Focus on clarity and engagement.`,
             Callouts: `- Capitalize for readability.\n- Highlight unique selling points.\n- Keep it concise, 5-20 characters.\n- Encourage clicks with impact.`,
             Keywords: "- Medium long-tail: 3-4 words each.\n- 1 keyword of 3 words and 2 of 4 words.\n- No hyphens & no consecutive long words.\n- Maximum 5 words per keyword."
          },
       },
    });
    const handleCampaignChange = (mode) => {
       setCampaignMode(mode);
       if (!campaignData[mode].menuItems[selectedMenu]) {
          const firstMenuItem = Object.keys(campaignData[mode].menuItems)[0];
          setSelectedMenu(firstMenuItem);
       }
    };
    
    // Map campaign mode to a class for dynamic styling
    const glowClass =
       campaignMode === "Broad search campaign"
          ? "backdrop-elm-white"
          : campaignMode === "Brand name campaign"
             ? "backdrop-elm-white"
             : "backdrop-elm-white";
    const campaignModes = [
       "Brand name campaign",
       "Broad search campaign",
    ];
    
    const currentModeIndex = campaignModes.indexOf(campaignMode); // Find the current mode's index
    
    const [dropdownOpen, setDropdownOpen] = useState({ campaign: false, principle: false });
    
    
    // State
    const [selectedLanguage, setSelectedLanguage] = useState("English");
    const [languageSelected, setLanguageSelected] = useState(false);
    
    // For the principle
    const [principle, setPrinciple] = useState("None");
    const [principleSelected, setPrincipleSelected] = useState(false);
    
    // Display logic
    const getDisplayLanguage = () => {
    // If the user has *never* manually chosen anything and the default is "English"
    if (!languageSelected && selectedLanguage === "English") {
     return "Language";
    }
    // Once the user *has* manually chosen "English" (or anything else), display it
    return selectedLanguage;
    };
    
    const getDisplayPrinciple = () => {
    // If the user has *never* manually chosen anything and the default is "None"
    if (!principleSelected && principle === "None") {
     return "Cialdini Principle";
    }
    return principle;
    };
    
    // Handlers
    const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
    setLanguageSelected(true);   // Indicate the user *did* choose something
    toggleDropdown("campaign");
    };
    
    const handlePrincipleSelect = (option) => {
    setPrinciple(option);
    setPrincipleSelected(true);
    toggleDropdown("principle");
    };
    
    
    
    // Toggle dropdown visibility
    const toggleDropdown = (type) => {
       setDropdownOpen((prev) => ({
          campaign: type === "campaign" ? !prev.campaign : false,
          principle: type === "principle" ? !prev.principle : false,
       }));
    };
    
    const handleOptionSelect = (type, value, event) => {
       event.stopPropagation();
       if (type === "campaign") {
         setSelectedLanguage(value);
         setLanguageSelected(true); // Mark language as selected
       } else if (type === "principle") {
         setPrinciple(value);
         setPrincipleSelected(true); // Mark principle as selected
       }
       setDropdownOpen((prev) => ({
         ...prev,
         [type]: false,
       }));
     };
    useEffect(() => {
       const handleClickOutside = (event) => {
          if (
             !event.target.closest(".custom-dropdown.selectcm") &&
             !event.target.closest(".custom-dropdown.selectcp")
          ) {
             setDropdownOpen({ campaign: false, principle: false });
          }
       };
    
       document.addEventListener("click", handleClickOutside);
       return () => {
          document.removeEventListener("click", handleClickOutside);
       };
    }, []);
    
    // 1) Easing function
    const easeInOutQuad = (t) =>
       t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2;
    
    // 2) Reusable fade logic
    const fadeInOutEased = (value, start, end, lockAtPeak = false) => {
       if (value < start) return 0;
       if (value > end && !lockAtPeak) return 0;
    
       const mid = (start + end) / 2;
    
       if (lockAtPeak && value >= mid) {
          // Once we hit peak, stay at 0.9
          return 0.9;
       }
    
       if (value <= mid) {
          const ratio = (value - start) / (mid - start);
          return 0.9 * easeInOutQuad(ratio);
       } else {
          const ratio = (value - mid) / (end - mid);
          return 0.9 * (1 - easeInOutQuad(ratio));
       }
    };
    
    const [url, setUrl] = useState(null);
    const [mockup, setMockup] = useState({
       url: "https://www.brandname.com",
       headline: "Your Brandname - Enter Your URL Above",
       description: "Enter a Website and instantly Generate PPC search ads. Experience how Easy it is to Launch Ads with AI—no expertise requi",
       display_url: "yourwebsite.com",
       sitelinks: [
          { title: "Try for Yourself", description: "Generate a PPC ad for your website now." },
          { title: "It's Completely Free", description: "See how AI makes ad creation effortless." },
          { title: "Your Page here", description: "Let's customize your journey with us." },
          { title: "Launch in 5 Min", description: "Try for yourself by entering a URL above." },
       ],
    });
    const [mockupLoading, setMockupLoading] = useState(false);
    const [extractLoading, setExtractLoading] = useState(false);
    const COSTS = {
       headline: 50,
       descriptions: 50,
       sitelinks: 50,
       callout: 50,
       snippets: 50,
    };
    const calculateTotalCost = () => {
       const fields = ['headline', 'descriptions', 'sitelinks', 'callout', 'snippets'];
       let cost = 0;
    
       // Safely iterate through fields and add their cost
       fields.forEach((field) => {
          if (checkedItems[field]) {
             cost += COSTS[field];
          }
       });
    
       // Safely parse `numberOfAds` or default to 1
       const adsCount = numberOfAds > 0 ? numberOfAds : 1;
    
       // Final cost calculation
       setTotalCost(cost * adsCount);
    };
    
    useEffect(() => {
       calculateTotalCost();
    }, []);
    
    useEffect(() => {
       calculateTotalCost();
    }, [numberOfAds, checkedItems]);
    
    const generateMockup = async () => {
       if (url) {
          const formattedUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
          try {
             setMockupLoading(true)
    
             const response = await AuthFetch2('campaign-gen/mockupGen', {
                method: 'POST',
                headers: {
                   'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                   url: formattedUrl
                })
             });
    
    
             const data = await response.json()
             if (data?.message) {
                setMockupLoading(false)
                return alert(data?.message)
             }
    
             setMockup(data?.mockup);
             setMockupLoading(false)
    
             //scroll to mockup gpt
             if (mockupRef.current) {
                mockupRef.current.scrollIntoView({ behavior: 'smooth' });
             }
          } catch (error) {
             console.log(error)
          }
    
       } else {
          alert('Please fill valid url')
       }
    
    }
    
    const mockupRef = useRef(null); //gpt scroll to mockup
    
    const handleCheckChange = (e) => {
       const { name, checked } = e.target;
    
       if (name === "all") {
          // Toggle all options
          const newCheckedItems = {
             all: checked,
             headline: checked,
             descriptions: checked,
             sitelinks: checked,
             callout: checked,
             snippets: checked,
          };
          setCheckedItems(newCheckedItems);
       } else {
          // Toggle individual option
          setCheckedItems((prev) => {
             const newCheckedItems = { ...prev, [name]: checked };
    
             // Update "all" if all other checkboxes are selected
             const allSelected = Object.keys(newCheckedItems)
                .filter((key) => key !== "all")
                .every((key) => newCheckedItems[key]);
    
             newCheckedItems.all = allSelected;
    
             return newCheckedItems;
          });
       }
    
       calculateTotalCost();
    };
    
    const formatUrl = (url) => {
       // Remove protocol (https:// or http://)
       let formattedUrl = url.replace(/(^\w+:|^)\/\//, '');
    
       // Remove 'www.' if it exists
       formattedUrl = formattedUrl.replace(/^www\./, '');
    
       // Remove everything after the first '.'
       formattedUrl = formattedUrl.split('.')[0];
    
       return formattedUrl;
    };
    const getValidInstruction = (field) => {
       return field.trim() === "" ? null : field;
    };
    const handleExtract = async () => {
       if (!isAuthenticated) return navigate('/login');
       if (!url) return alert('Please fill valid url');
       const formattedUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
    
       setExtractLoading(true);
       const accessToken = localStorage.getItem('accessToken')
       const { headline, descriptions, sitelinks, callout, snippets } = checkedItems
       const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/campaign-gen/extract`, {
          method: 'POST',
          headers: {
             'Content-Type': 'application/json',
             'Authorization': `Bearer ${accessToken}`
          },
          body: JSON.stringify({
             url: formattedUrl, headline, descriptions,
             sitelinks, callout, snippets,
             count: numberOfAds, campaignMode, cialdini: principle === "Cialdini's principle" ? "none" : principle,
             countk: numberOfKeywords,
             specialInstructionsHeadlines: getValidInstruction(
                campaignMode === "Brand name campaign"
                   ? campaignData["Brand name campaign"].menuItems.Headlines
                   : campaignData["Broad search campaign"].menuItems.Headlines
             ),
             specialInstructionsDescriptions: getValidInstruction(
                campaignMode === "Brand name campaign"
                   ? campaignData["Brand name campaign"].menuItems.Descriptions
                   : campaignData["Broad search campaign"].menuItems.Descriptions
             ),
             specialInstructionsSitelinks: getValidInstruction(
                campaignMode === "Brand name campaign"
                   ? campaignData["Brand name campaign"].menuItems.Sitelinks
                   : campaignData["Broad search campaign"].menuItems.Sitelinks
             ),
             specialInstructionsSnippets: getValidInstruction(
                campaignMode === "Brand name campaign"
                   ? campaignData["Brand name campaign"].menuItems.Snippets
                   : campaignData["Broad search campaign"].menuItems.Snippets
             ),
             specialInstructionsCallouts: getValidInstruction(
                campaignMode === "Brand name campaign"
                   ? campaignData["Brand name campaign"].menuItems.Callouts
                   : campaignData["Broad search campaign"].menuItems.Callouts
             ),
             specialInstructionsKeywords: getValidInstruction(
                campaignMode === "Brand name campaign"
                   ? campaignData["Brand name campaign"].menuItems.Keywords
                   : campaignData["Broad search campaign"].menuItems.Keywords
             ),
             language: selectedLanguage
          })
       });
    
       if (response.ok) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
    
          // Create an anchor element and trigger a download
          const a = document.createElement('a');
          a.href = url;
          a.download = 'campaign_template.xlsx'; // Set the filename for download
          document.body.appendChild(a);
          a.click();
    
          // Cleanup: Remove the link element and revoke the blob URL
          a.remove();
          window.URL.revokeObjectURL(url);
          setExtractLoading(false)
    
       } else if (response.status === 401) {
          const { accessToken: newAccessToken } = await refresh();
          const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/campaign-gen/extract`, {
             method: 'POST',
             headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${newAccessToken}`
             },
             body: JSON.stringify({
                url, language: selectedLanguage, headline, descriptions, sitelinks, callout, snippets
             })
          });
    
          if (res.ok) {
             const blob = await res.blob();
             const url = window.URL.createObjectURL(blob);
    
             // Create an anchor element and trigger a download
             const a = document.createElement('a');
             a.href = url;
             a.download = 'campaign_template.xlsx'; // Set the filename for download
             document.body.appendChild(a);
             a.click();
    
             // Cleanup: Remove the link element and revoke the blob URL
             a.remove();
             window.URL.revokeObjectURL(url);
             setExtractLoading(false)
          } else {
             const data = await response.json();
             alert(data.message);
             setExtractLoading(false)
          }
       } else {
          const data = await response.json();
          alert(data?.message)
          setExtractLoading(false)
       }
    
    }
    
    
    useEffect(() => {
       document.title = "Instant Google Ads Generator - Launch PPC ads in Seconds";
    
       const metaDescription = document.querySelector('meta[name="description"]');
       if (metaDescription) {
          metaDescription.setAttribute("content", "Generate PPC ads instantly using AI. Enter your website to Create, Export and Launch Ads to All Search Engines. Simple, Fast, and Good-Looking.");
       }
    
       const metaKeywords = document.querySelector('meta[name="keywords"]');
       if (metaKeywords) {
          metaKeywords.setAttribute("content", "PPC advertising, ai search ads, google ads ai, create search ads");
       }
    
       // Open Graph tags
       const ogTitle = document.querySelector('meta[property="og:title"]');
       if (ogTitle) {
          ogTitle.setAttribute("content", "Instant Google Ads Generator - Launch PPC ads in Seconds");
       }
    
       const ogDescription = document.querySelector('meta[property="og:description"]');
       if (ogDescription) {
          ogDescription.setAttribute("content", "Generate PPC ads instantly using AI. Enter your website to Create, Export and Launch Ads to All Search Engines. Simple, Fast, and Good-Looking.");
       }
    
       const ogImage = document.querySelector('meta[property="og:image"]');
       if (ogImage) {
          ogImage.setAttribute("content", "URL_to_image_for_preview");
       }
    
       const ogUrl = document.querySelector('meta[property="og:url"]');
       if (ogUrl) {
          ogUrl.setAttribute("content", "https://www.ppcc.ai");
       }
    
       // Twitter Card tags
       const twitterTitle = document.querySelector('meta[name="twitter:title"]');
       if (twitterTitle) {
          twitterTitle.setAttribute("content", "Instant Google Ads Generator - Launch PPC ads in Seconds");
       }
    
       const twitterDescription = document.querySelector('meta[name="twitter:description"]');
       if (twitterDescription) {
          twitterDescription.setAttribute("content", "Generate PPC ads instantly using AI. Enter your website to Create, Export and Launch Ads to All Search Engines. Simple, Fast, and Good-Looking.");
       }
    
       const twitterImage = document.querySelector('meta[name="twitter:image"]');
       if (twitterImage) {
          twitterImage.setAttribute("content", "URL_to_image_for_twitter");
       }
    
       const twitterCard = document.querySelector('meta[name="twitter:card"]');
       if (twitterCard) {
          twitterCard.setAttribute("content", "summary_large_image");
       }
    
    }, []);
    
    const [showAuthenticated, setShowAuthenticated] = useState(isAuthenticated);
    
    useEffect(() => {
       setShowAuthenticated(isAuthenticated);
    }, [isAuthenticated]); // Runs whenever isAuthenticated changes
    useEffect(() => {
       document.body.style.overflow = showAuthenticated ? "hidden" : "";
    
       const handleNavigation = () => {
           document.body.style.overflow = "";
       };
    
       window.addEventListener("popstate", handleNavigation);
    
       return () => {
           document.body.style.overflow = "";
           window.removeEventListener("popstate", handleNavigation);
       };
    }, [showAuthenticated]);
    
    const [fadeClass, setFadeClass] = useState("fade-in");
    const [showMainContent, setShowMainContent] = useState(false);
    
    const handleExit = () => {
       setFadeClass("fade-out"); // Start fade-out animation
       setTimeout(() => {
           setShowAuthenticated(false); // Hide authenticated section
           setShowMainContent(true);
           setCampaignMode("Brand name campaign"); // Show the main content
           setFadeClass("fade-in"); // Reset fade-in effect
       }, 600); // Matches transition duration
    };
    
    const handleGoBack = (e) => {
       e.preventDefault();
       window.scrollTo({ top: 0, behavior: 'smooth' });
       setTimeout(() => {
           setFadeClass("fade-out");
    
           setTimeout(() => {
               setShowMainContent(false);
               setShowAuthenticated(true);
               setCampaignMode("Broad search campaign");
               setFadeClass("fade-in");
           }, 400);
       }, 500);
    };
    return (
       <>
          <Loader isLoading={mockupLoading || extractLoading} />
          
          <div className="main">
             <div className="outer-banner position-relative">
                <section className="banner text-center banner-home">
                   <div className="d-md-block d-none">
                      <BackdropAnimation glowClass={glowClass} delay={1.5} />
                   </div>
                   <div className="d-md-none d-block">
                      <BackdropGlow />
                   </div>
    
                   <div className="container mobileonly creditspagemobile">
                   <a className="leftopcorner linknostyle backmobilee" href="/">
            ← Back
          </a>
                      <AnimatedSection>
                         <div className="top-title mb-md-5 pb-1 mb-2">Great news</div>
                      </AnimatedSection>
                      <AnimatedSection delay={0.2}>
                         <h1 className="h1 h1mobile">Credits are 100% Free</h1>
                      </AnimatedSection>
                      <AnimatedSection delay={0.3}>
                         <div className="desc mb-4">
                            <p className="p-18 generatep">Interested in more credits? Send us an email at <span class="linknostyle">support@ppcc.ai</span> and we will replenish your account.
                            </p>
                         </div>
                      </AnimatedSection>
                      <HeightAnimatedSection delay={0.4}>
                         <div className="banner-form-sec mt-md-5 mobilehomesec morecreditspage">
                            
    
                         </div>
                         
                      </HeightAnimatedSection>
    
                   </div>
                   <div className="container desktoponly freecreditspaged">
                   <a className="leftopcorner linknostyle backmobilee" href="/">
            ← Back
          </a>
                      <motion.div style={{ scale: smoothScale, overflow: "visible", position: "relative" }}>
                         <div style={{ overflow: "visible", position: "relative" }}> {/* Additional layer to isolate scaling */}
                            <AnimatedSection delay={0.2}>
                               <div className="top-title mb-md-5 pb-1 mb-2">Great news</div>
                            </AnimatedSection>
                            
                            <AnimatedSection delay={0.4}>
                               <h1 className="h1">At this time, Credits are 100% Free</h1>
                            </AnimatedSection>
                            <AnimatedSection delay={0.6}>
                               <div className="desc mb-4">
                                  
                                  <p class="p-18 generatep">Interested in more credits? Send us an email at <span class="linknostyle">support@ppcc.ai</span> and we will replenish your account.</p>
                               </div>
                            </AnimatedSection>
    
                            <div className="banner-form-sec mt-md-5 morecreditsbub">
                               
                               
                               <div className="modal-body morecreditspage"></div>
                            </div>
                         </div>
                      </motion.div>
                     
                   </div>
                </section>
    
                
             </div>
             
          </div>
       </>
    );
};


export default Generated;
