import React, { useState, useEffect, useRef } from "react";
import "../Style/generatetool.css";
import AnimatedSection from "../Components/AnimatedSection";
import Authwall from "../Components/Authwall";
import { AuthFetch, AuthFetch2, refresh, signUpWithGoogle, signUpWithMicrosoft } from "../lib/auth";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import BackdropAnimation from "../Components/BackdropAnimation";
import BackdropGlow from "../Components/BackdropGlow";
import { useUser } from "../contexts/userContext";
import HeightAnimatedSection from "../Components/HeightAnimatedSection";
import { motion, useSpring, useTransform, useViewportScroll } from "framer-motion";
import { TbRosetteDiscountCheckFilled } from "react-icons/tb";
import { RxCross2 } from "react-icons/rx";
import { FaCheck } from "react-icons/fa6";
import Lightbox from "../Components/Lightbox";






function Homepage() {
   const { userPlan } = useAuth();
   const isAuthenticated = useAuth();
   const navigate = useNavigate()
   const [url, setUrl] = useState(null);
   const { scrollY } = useViewportScroll();
   const scale = useTransform(scrollY, [0, 1000], [1, 0.9]);
   const smoothScale = useSpring(scale, { stiffness: 50, damping: 10 });
    const [mockup, setMockup] = useState({
        url: "https://www.brandname.com",
        headline: "Your Brandname - Enter Your URL Below",
        description: "Enter a Website and instantly Generate PPC search ads. Experience how Easy it is to Launch Ads with AI—no expertise required.",
        display_url: "yourwebsite.com",
        sitelinks: [
            { title: "Try for Yourself", description: "Generate a PPC ad for your website now." },
            { title: "It's Completely Free", description: "See how AI makes ad creation effortless." },
            { title: "Your Page here", description: "Let's customize your journey with us." },
            { title: "Launch in 5 Min", description: "Try for yourself by entering a URL above." },
        ],
    });


    // NEW: Lightbox state and screenshot list
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  const screenshots = [
    "/images/dashscreenn1.png",
    "/images/creatordashboardscreen.png",
    "/images/spotifyexcelscreen.png",
    "/images/googleadseditorscreen.png",
];

  const openLightbox = (index) => {
    setLightboxIndex(index);
    setLightboxOpen(true);
  };

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        handleResize(); // Set initial state
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const Wrapper = isMobile ? React.Fragment : AnimatedSection;


    const formatUrl = (url) => {
        // Remove protocol (https:// or http://)
        let formattedUrl = url.replace(/(^\w+:|^)\/\//, '');
  
        // Remove 'www.' if it exists
        formattedUrl = formattedUrl.replace(/^www\./, '');
  
        // Remove everything after the first '.'
        formattedUrl = formattedUrl.split('.')[0];
  
        return formattedUrl;
     };

    const Mocksite = [
        { site_text: "Try for Yourself", sitePara: "Generate a PPC ad for your website now." },
        { site_text: "It's Completely Free", sitePara: "See how AI makes ad creation effortless." },
        { site_text: "Your Page here", sitePara: "Let's customize your journey with us." },
        { site_text: "Launch in 5 Minutes", sitePara: "Try for yourself by entering a URL above." },
    ];

    const [mockupLoading, setMockupLoading] = useState(false);
    const handleStartAndGenerateMockup = async () => {
        if (!url) {
            alert('Please fill valid url');
            return;
        }
    
        const formattedUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
        
        setIsStarted(true);
        setStepOneCompleted(true);
        handleScrollToMockup();
    
        try {
            setMockupLoading(true);
            const response = await AuthFetch2('campaign-gen/mockupGen', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    url: formattedUrl
                })
            });
    
            const data = await response.json();
            if (data?.message) {
                setMockupLoading(false);
                return alert(data?.message);
            }
    
            setMockup(data?.mockup);
            setMockupLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

     const [checkedItems, setCheckedItems] = useState({
        all: true,
        headline: true,
        descriptions: true,
        sitelinks: true,
        callout: true,
        snippets: true,
        sheetURL: true,
     });
    const [numberOfAds, setNumberOfAds] = useState(1);
    const [numberOfKeywords, setNumberOfKeywords] = useState(5);
    const [selectedMenu, setSelectedMenu] = useState('Headlines');
    const [campaignData, setCampaignData] = useState({
        "Broad search campaign": {
         menuItems: {
            Headlines: `- Capitalize for readability.\n- Engaging, to the point.\n- Aim for CTR and CTA.\n- 30 characters max.`,
            Descriptions: `- Capitalize for readability.\n- Clear, concise, actionable.\n- Focus on product value.\n- 90 characters max.`,
            Sitelinks: `- Capitalize for readability.\n- Name matches page intent.\n- Descriptions: 7-12 words max.\n- Provide clear final URLs.`,
            Snippets: `- Select predefined headers.\n- Each header: 4 relevant items.\n- Align with ad content.\n- Focus on clarity and engagement.`,
            Callouts: `- Capitalize for readability.\n- Highlight unique selling points.\n- Keep it concise, 5-20 characters.\n- Encourage clicks with impact.`,
            Keywords: "- Medium long-tail: 3-4 words each.\n- 1 keyword of 3 words and 2 of 4 words.\n- No hyphens & no consecutive long words.\n- Maximum 5 words per keyword."
         },
      },
    });
    const [campaignMode, setCampaignMode] = useState('Broad search campaign');
    const [showCustomInstructions, setShowCustomInstructions] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState({ campaign: false, principle: false });
    const maxInstructionsLength = 200;
    const glowClass =
      campaignMode === "Broad search campaign"
         ? "backdrop-elm-white"
         : campaignMode === "Brand name campaign"
            ? "backdrop-elm-white"
            : "backdrop-elm-white";
   const campaignModes = [
      "Brand name campaign",
      "Broad search campaign",
   ];

    const handleInstructionsChange = (e) => {
        const newInstructions = e.target.value;
        if (newInstructions.length <= maxInstructionsLength) {
            setCampaignData(prevData => ({
                ...prevData,
                [campaignMode]: {
                    ...prevData[campaignMode],
                    menuItems: {
                        ...prevData[campaignMode].menuItems,
                        [selectedMenu]: newInstructions
                    }
                }
            }));
        }
    };

    // State
    const [selectedLanguage, setSelectedLanguage] = useState("English");
    const [languageSelected, setLanguageSelected] = useState(false);

    // For the principle
    const [principle, setPrinciple] = useState("None");
    const [principleSelected, setPrincipleSelected] = useState(false);

    // Display logic
    const getDisplayLanguage = () => {
        // If the user has *never* manually chosen anything and the default is "English"
        if (!languageSelected && selectedLanguage === "English") {
            return "Language";
        }
        // Once the user *has* manually chosen "English" (or anything else), display it
        return selectedLanguage;
    };

    const getDisplayPrinciple = () => {
        // If the user has *never* manually chosen anything and the default is "None"
        if (!principleSelected && principle === "None") {
            return "Cialdini Principle";
        }
        return principle;
    };

    const toggleDropdown = (type) => {
        setDropdownOpen((prev) => ({
           campaign: type === "campaign" ? !prev.campaign : false,
           principle: type === "principle" ? !prev.principle : false,
        }));
     };
  
     const handleOptionSelect = (type, value, event) => {
        event.stopPropagation();
        if (type === "campaign") {
          setSelectedLanguage(value);
          setLanguageSelected(true); // Mark language as selected
        } else if (type === "principle") {
          setPrinciple(value);
          setPrincipleSelected(true); // Mark principle as selected
        }
        setDropdownOpen((prev) => ({
          ...prev,
          [type]: false,
        }));
      };
     useEffect(() => {
        const handleClickOutside = (event) => {
           if (
              !event.target.closest(".custom-dropdown.selectcm") &&
              !event.target.closest(".custom-dropdown.selectcp")
           ) {
              setDropdownOpen({ campaign: false, principle: false });
           }
        };
  
        document.addEventListener("click", handleClickOutside);
        return () => {
           document.removeEventListener("click", handleClickOutside);
        };
     }, []);

     const [extractLoading, setExtractLoading] = useState(false);
     const [extractFile, setExtracted] = useState(false);
     const { user } = useUser();

     const getValidInstruction = (field) => {
        return field.trim() === "" ? null : field;
     };
     const handleExtract = async () => {
        if (!url) return alert('Please fill valid url');
        const formattedUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;

   // Minimal credit check using the user's credits (displayed in header as {user?.credits?.toLocaleString('de-DE')})
   if (user?.credits < totalCost) {
      alert(`Not enough credits. You have ${user.credits} credits, but you require ${totalCost} credits.`);
      return;
   }

        setExtractLoading(true);
        const accessToken = localStorage.getItem('accessToken')
        const { headline, descriptions, sitelinks, callout, snippets } = checkedItems
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/campaign-gen/extract`, {
           method: 'POST',
           headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
           },
           body: JSON.stringify({
              url: formattedUrl, headline, descriptions,
              sitelinks, callout, snippets,
              count: numberOfAds, campaignMode, cialdini: principle === "Cialdini's principle" ? "none" : principle,
              countk: numberOfKeywords,
              specialInstructionsHeadlines: getValidInstruction(
                 campaignMode === "Brand name campaign"
                    ? campaignData["Brand name campaign"].menuItems.Headlines
                    : campaignData["Broad search campaign"].menuItems.Headlines
              ),
              specialInstructionsDescriptions: getValidInstruction(
                 campaignMode === "Brand name campaign"
                    ? campaignData["Brand name campaign"].menuItems.Descriptions
                    : campaignData["Broad search campaign"].menuItems.Descriptions
              ),
              specialInstructionsSitelinks: getValidInstruction(
                 campaignMode === "Brand name campaign"
                    ? campaignData["Brand name campaign"].menuItems.Sitelinks
                    : campaignData["Broad search campaign"].menuItems.Sitelinks
              ),
              specialInstructionsSnippets: getValidInstruction(
                 campaignMode === "Brand name campaign"
                    ? campaignData["Brand name campaign"].menuItems.Snippets
                    : campaignData["Broad search campaign"].menuItems.Snippets
              ),
              specialInstructionsCallouts: getValidInstruction(
                 campaignMode === "Brand name campaign"
                    ? campaignData["Brand name campaign"].menuItems.Callouts
                    : campaignData["Broad search campaign"].menuItems.Callouts
              ),
              specialInstructionsKeywords: getValidInstruction(
                 campaignMode === "Brand name campaign"
                    ? campaignData["Brand name campaign"].menuItems.Keywords
                    : campaignData["Broad search campaign"].menuItems.Keywords
              ),
              language: selectedLanguage
           })
        });
  
        if (response.ok) {
           const blob = await response.blob();
           const url = window.URL.createObjectURL(blob);
  
           // Create an anchor element and trigger a download
           const a = document.createElement('a');
           a.href = url;
           a.download = 'campaign_template.xlsx'; // Set the filename for download
           document.body.appendChild(a);
           a.click();
  
           // Cleanup: Remove the link element and revoke the blob URL
           a.remove();
           window.URL.revokeObjectURL(url);
           setExtractLoading(false)
           setExtracted(true)
  
        } else if (response.status === 401) {
           const { accessToken: newAccessToken } = await refresh();
           const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/campaign-gen/extract`, {
              method: 'POST',
              headers: {
                 'Content-Type': 'application/json',
                 'Authorization': `Bearer ${newAccessToken}`
              },
              body: JSON.stringify({
                 url, language: selectedLanguage, headline, descriptions, sitelinks, callout, snippets
              })
           });
  
           if (res.ok) {
              const blob = await res.blob();
              const url = window.URL.createObjectURL(blob);
  
              // Create an anchor element and trigger a download
              const a = document.createElement('a');
              a.href = url;
              a.download = 'campaign_template.xlsx'; // Set the filename for download
              document.body.appendChild(a);
              a.click();
  
              // Cleanup: Remove the link element and revoke the blob URL
              a.remove();
              window.URL.revokeObjectURL(url);
              setExtractLoading(false)
           } else {
              const data = await response.json();
              alert(data.message);
              setExtractLoading(false)
           }
        } else {
           const data = await response.json();
           alert(data?.message)
           setExtractLoading(false)
        }
  
     }


     //COSTSSSSS
     const [totalCost, setTotalCost] = useState(250);

     const COSTS = {
        headline: 50,
        descriptions: 50,
        sitelinks: 50,
        callout: 50,
        snippets: 50,
     };


     const calculateTotalCost = () => {
        const fields = ['headline', 'descriptions', 'sitelinks', 'callout', 'snippets'];
        let cost = 0;
  
        // Safely iterate through fields and add their cost
        fields.forEach((field) => {
           if (checkedItems[field]) {
              cost += COSTS[field];
           }
        });
  
        // Safely parse `numberOfAds` or default to 1
        const adsCount = numberOfAds > 0 ? numberOfAds : 1;
  
        // Final cost calculation
        setTotalCost(cost * adsCount);
     };
  
     useEffect(() => {
        calculateTotalCost();
     }, []);
  
     useEffect(() => {
        calculateTotalCost();
     }, [numberOfAds, checkedItems]);


    const [isStarted, setIsStarted] = useState(false);
const [stepOneCompleted, setStepOneCompleted] = useState(false);
const [stepTwoCompleted, setStepTwoCompleted] = useState(false);
const [stepThreeCompleted, setStepThreeCompleted] = useState(false);
const [stepFourCompleted, setStepFourCompleted] = useState(false);
const [stepFiveCompleted, setStepFiveCompleted] = useState(false);
const [stepSixCompleted, setStepSixCompleted] = useState(false);
const [stepThreehalfCompleted, setStepThreehalfCompleted] = useState(false);
const [stepSixHalfCompleted, setStepSixHalfCompleted] = useState(false);


const handleContinueToSixHalf = () => {
    setStepSixHalfCompleted(true);
};

const handleContinue = () => {
    setStepTwoCompleted(true);
};

const handleContinueToFour = () => {
    setStepThreeCompleted(true);
};

const handleAuthSuccess = () => {
    setStepThreehalfCompleted(true)
 };

const handleContinueToFive = () => {
    setStepFourCompleted(true);
};
const handleContinueToSix = () => {
    setStepFiveCompleted(true);
};

const handleContinueToSeven = () => {
    setStepSixCompleted(true);
};

const mobileMockupRef = useRef(null);
const handleScrollToMockup = () => {
    if (window.innerWidth < 768 && mobileMockupRef.current) {
      mobileMockupRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

const [showLimits, setShowLimits] = useState(false);

const handleReturn = () => {
    setIsStarted(false);
    setStepOneCompleted(false);
    setStepTwoCompleted(false);
    setStepThreeCompleted(false);
    setStepFourCompleted(false);
    setStepFiveCompleted(false)
    setStepSixCompleted(false);
    setStepSixHalfCompleted(false);
    setStepThreehalfCompleted(false);
    setShowLimits(false);
};

  const handleShowLimits = () => {
    setShowLimits((prevShow) => !prevShow);
  };

  useEffect(() => {
  document.title = "AI-Powered PPC Campaigns in Minutes | PPCC.ai";

  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute("content", "One-minute PPC campaigns that perform. Instantly generate and optimize high-quality search ads with AI. Save time and boost performance with PPCC.ai.");
  }

  const metaKeywords = document.querySelector('meta[name="keywords"]');
  if (metaKeywords) {
    metaKeywords.setAttribute("content", "PPC automation, AI PPC, Google Ads AI, AI search ads, digital marketing automation, PPC optimization, PPCC.ai");
  }

  // Open Graph tags
  const ogTitle = document.querySelector('meta[property="og:title"]');
  if (ogTitle) {
    ogTitle.setAttribute("content", "AI-Powered PPC Campaigns in Minutes | PPCC.ai");
  }

  const ogDescription = document.querySelector('meta[property="og:description"]');
  if (ogDescription) {
    ogDescription.setAttribute("content", "One-minute PPC campaigns that perform. Instantly generate and optimize high-quality search ads with AI. Save time and boost performance with PPCC.ai.");
  }

  const ogImage = document.querySelector('meta[property="og:image"]');
  if (ogImage) {
    ogImage.setAttribute("content", "URL_to_homepage_image");
  }

  const ogUrl = document.querySelector('meta[property="og:url"]');
  if (ogUrl) {
    ogUrl.setAttribute("content", "https://www.ppcc.ai/");
  }

  // Twitter Card tags
  const twitterTitle = document.querySelector('meta[name="twitter:title"]');
  if (twitterTitle) {
    twitterTitle.setAttribute("content", "AI-Powered PPC Campaigns in Minutes | PPCC.ai");
  }

  const twitterDescription = document.querySelector('meta[name="twitter:description"]');
  if (twitterDescription) {
    twitterDescription.setAttribute("content", "Instantly create and optimize high-performing PPC campaigns using AI. Get started with PPCC.ai today.");
  }

  const twitterImage = document.querySelector('meta[name="twitter:image"]');
  if (twitterImage) {
    twitterImage.setAttribute("content", "URL_to_homepage_image");
  }

  const twitterCard = document.querySelector('meta[name="twitter:card"]');
  if (twitterCard) {
    twitterCard.setAttribute("content", "summary_large_image");
  }

}, []);

    return (


        <div className="main">
            
            
            
            <div class="fullscreen-div ffweg">
<div class="maintoolcontent">
            <div class={`urlmagnet thefirststep ${stepOneCompleted ? 'fade-out' : ''}`}>
            <div class="titledivv"><h1 class="h1">Start here</h1></div>
            <div class="urlmagnetformdiv"><form className="d-md-flex justify-content-center align-items-center generateform">
                    <div className="form-group me-0 me-md-3 ">
                    <input
                                    placeholder="https://www.yourwebsite.com"
                                    className="form-control yoururl"
                                    name="site_url"
                                    value={url}
                                    onChange={(e) => { setUrl(e.target.value) }}
                                    autocomplete="url"

                                 />
                    </div>

                    <div className="submit-btn mt-md-0 mt-3 ">
                        <button
                            type="button"
                            className="btn urlbtn"
                            onClick={handleStartAndGenerateMockup}
                        >
                            <span className={`btn-text`}>
                                Start
                            </span>

                        </button>
                    </div>
                </form></div>

            </div>
            <div class={`thesecondstep ${stepOneCompleted ? 'fade-in' : ''} ${stepTwoCompleted ? 'steptwocompleted' : ''}`}>
                <h1 class="h1 secondsteptitle">Customize</h1>
                <div className="columnstwo">
                <div className="settingsselect settingsselectc">
                    {/* Language Dropdown */}<div>‎</div>
                    <div classname="generateselectdiv">
                    <div className="custom-dropdown selectcm  generateselect" onClick={() => toggleDropdown("campaign")}>
                        <div className="custom-dropdown-selected">
                            {getDisplayLanguage()}
                            <svg className="custom-dropdown-chevron" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
                                <path fill="white" d="M7 10l5 5 5-5z" />
                            </svg>
                        </div>
                        {dropdownOpen.campaign && (
                            <ul className="custom-dropdown-options custom-dropdown-optionsc">
                                {["English", "Spanish", "French", "Dutch", "German"].map((language) => (
                                    <li key={language} className="custom-dropdown-option custom-dropdown-optionc" onClick={(e) => handleOptionSelect("campaign", language, e)}>
                                        {language}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    </div>
                    {/* Cialdini's Principle Dropdown */}
                    <div classname="generateselectdiv cialdinidiv">
                    <div className="custom-dropdown selectcp  generateselect" onClick={() => toggleDropdown("principle")}>
                        <div className="custom-dropdown-selected">
                            {getDisplayPrinciple()}
                            <svg className="custom-dropdown-chevron" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
                                <path fill="white" d="M7 10l5 5 5-5z" />
                            </svg>
                        </div>
                        {dropdownOpen.principle && (
                            <ul className="custom-dropdown-options custom-dropdown-optionsc">
                                {["Reciprocity", "Consistency", "Social Proof", "Authority", "Liking", "Scarcity", "None"].map((option) => (
                                    <li key={option} className="custom-dropdown-option" onClick={(e) => handleOptionSelect("principle", option, e)}>
                                        {option}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div></div>
                    <div>‎</div>
                    <button className="btn steptwobtn" onClick={handleContinue}>Continue</button>
                </div>
                <div class="txtar">
                    <div className="dynamic-textarea-wrapper">
                        {/* Navigation Menu for Special Instructions */}
                        
                        <div className="special-instructions-menu special-instructions-menunew">
                            {["Headlines", "Descriptions", "Sitelinks", "Callouts", "Snippets", "Keywords"].map((item) => (
                                <button
                                    key={item}
                                    className={`menubtnnew menu-btn ${selectedMenu === item ? "selected" : ""}`}
                                    onClick={() => setSelectedMenu(item)}
                                >
                                    {item}
                                </button>
                            ))}
                        </div>

                        {/* Textarea for Special Instructions */}
                        <div className="textarea-container txtnewi">
                            
                            <div className="tooltip-container tooltip-container1">
                                <span className="question-mark question-mark1">?</span>
                                <div className="tooltip-text tooltip-text1">
                                    <span className="tool-tip-title">Tips:</span>
                                    <ul>
                                        <li>- Maintain the prompt structure</li>
                                        <li>- Avoid removal of character limits</li>
                                        <li>- Empty to exclude from download</li>
                                    </ul>
                                </div>
                            </div>
                            <textarea
                                className="form-control custominform dynamic-textarea instructionsnew"
                                onFocus={() => setShowCustomInstructions(true)}
                                rows="4"
                                value={campaignData[campaignMode].menuItems[selectedMenu] || ""}
                                onChange={handleInstructionsChange}
                                placeholder={`Leave empty to skip ${selectedMenu}`}
                            />
                            <div className="charactercountert">
                                {campaignData[campaignMode].menuItems[selectedMenu]?.length || 0}/{maxInstructionsLength}
                            </div>
                        </div>
                    </div>
                </div></div>
            </div>
            <div class={`thethirdstep ${stepTwoCompleted ? 'fade-in' : ''} ${stepThreeCompleted ? 'fade-out' : ''}`}>
            <h1 class="h1 thirdsteptitle">Extract</h1>
            {isAuthenticated ? (
  <div className="extractcost extractcost2">
    <p className="p-16 moresettingstext nextinstead" style={{ marginLeft: "15px" }}>
      Estimated cost: <span style={{ color: "white" }}>{totalCost || 250} credits</span>
      <div className="tooltip-container">
        <span className="question-mark">?</span>
        <div className="tooltip-text">
          Credits are the in-app currency you spend to generate, refine, and download ad campaigns.
        </div>
      </div>
    </p>
  </div>
) : null}
            <div className="thirdsettingsline amountscc extractforms">
                        {/* Amount of Ads Input */}
                        <div className="label-sc1 label-sc1c align-items-center" style={{ fontSize: "14px" }}>
                            <label htmlFor="number_of_ads" className="me-2 amountofadsc2">Amount of ads</label>

                            <input placeholder="Enter number" className="amountofads realamount amountofadsc" type="number" id="number_of_ads" name="number_of_ads"
                                value={numberOfAds}
                                onChange={(e) => setNumberOfAds(Math.max(1, Number(e.target.value) || 1))}
                                min="1" max="5"
                            />
                            <div class="controlinputc"><span className="newestminus minusc minus-sign minus-sign-amount" onMouseDown={(e) => e.preventDefault()} onClick={() => setNumberOfAds((prev) => Math.max(1, prev - 1))}>-</span>
                                <span className="plus-sign plussignc newestplus" onMouseDown={(e) => e.preventDefault()} onClick={() => setNumberOfAds((prev) => Math.min(5, prev + 1))}>+</span></div>
                        </div>

                        {/* Amount of Keywords Input */}
                        <div className="label-keywords label-sc1 label-sc1c align-items-center" style={{ fontSize: "14px", marginTop: "10px" }}>
                            <label htmlFor="number_of_keywords" className="me-2 amountofkeywordsc">Amount of keywords</label>

                            <input placeholder="Enter number" className="amountofads amountofadsc" type="number" id="number_of_keywords" name="number_of_keywords"
                                value={numberOfKeywords}
                                onChange={(e) => setNumberOfKeywords(Math.max(5, Number(e.target.value) || 5))}
                                min="5" max="20"
                            />
                            <div class="controlinputc2"><span className="plussignc plus-sign newestplus2" onMouseDown={(e) => e.preventDefault()} onClick={() => setNumberOfKeywords((prev) => Math.min(20, prev + 5))}>+</span>
                                <span className="newestminus2 minusc minus-sign mmam" onMouseDown={(e) => e.preventDefault()} onClick={() => setNumberOfKeywords((prev) => Math.max(5, prev - 5))}>-</span>
                            </div>
                        </div>
                        </div>
            <button className="btn stepthreebutton"onClick={() => (isAuthenticated ? (handleExtract(), handleContinueToFour(), handleContinueToFive()) : handleContinueToFour())}>{isAuthenticated ? "Download" : "Continue"}</button>
            </div>
            <div class={`thefourthstep ${stepThreeCompleted ? 'fade-in' : ''} ${stepFourCompleted ? 'fade-out' : ''}`}>
            <h1 class="h1 fourthsteptitle">{stepThreehalfCompleted ? "Success" : "Download"}</h1>
            
            <div class={`authdiv ${stepThreehalfCompleted ? 'fade-out' : ''}`}>
                <p class="authlocksentence">To download your campaign, create an account:</p>
            <Authwall onAuthSuccess={handleAuthSuccess} />
            </div>
           
            <div class={`whatsnextdiv ${stepThreehalfCompleted ? 'fade-in' : ''}`}>
            <button className="btn stepthreebutton" onClick={() => { handleExtract(); handleContinueToFive(); }}>Download</button>
            <div className="extractcost extractcost3">
    <p className="p-16 moresettingstext nextinstead" style={{ marginLeft: "15px" }}>
      Estimated cost: <span style={{ color: "white" }}>{totalCost || 250} credits</span>
      <div className="tooltip-container">
        <span className="question-mark">?</span>
        <div className="tooltip-text">
          Credits are the in-app currency you spend to generate, refine, and download ad campaigns.
        </div>
      </div>
    </p>
  </div>
            </div>
            </div>
            <div class={`thefifthstep thesixststep ${stepFourCompleted ? 'fade-in' : ''}`}>
            <h1 class="h1 fourthsteptitle">Import</h1>
            
            <p class={`fourthstepdesc ${stepSixCompleted ? 'fade-out' : ''}`}><span className={`${extractLoading ? 'extract-loading-animation' : ''}`}>{extractFile ? 'Campaign downloaded.' : 'Downloading campaign...'}</span>{extractFile ? " What's next:" : " While you wait:"}</p>
                <div class={`columnsz ${stepSixCompleted ? 'fade-out' : ''}`}>
                    <div class="columnonee">
    <img class="smallimgg"src="/images/googleeditor.png" alt="Google Editor" />
                    </div>
                    
                    <div class="columntwoo">
                    <p>Open the Google ads Editor</p>
                    <p class="secondpma">or install it <a class="linknostyle" href="https://ads.google.com/home/tools/ads-editor/" target="_blank">here</a>.</p>
                    </div>
                    </div>
             

                <div class={`columnsz columnsz2 ${stepFiveCompleted ? 'fade-in' : ''} ${stepSixCompleted ? 'fade-out' : ''}`}>
                    <div class="columnonee">
                    <img class="smallimgg"src="/images/excelogo.png" alt="Excel Logo" />
                    </div>
                    
                    <div class="columntwoo">
                    <p>Shorten the cells highlighted red.</p>
                    <p class="secondpma">View character limits <a class="linknostyle"onClick={handleShowLimits}>here</a>.</p>
                    
                    <div class={`characterlimits ${showLimits ? 'fade-in' : 'fade-out'}`}>
                        
                        <table className="listoe" style={{ width: '100%', borderCollapse: 'collapse' }}>
          <tbody>
            <tr>
              <td>Headlines</td>
              <td> ‎  ‎  ‎  ‎  ‎  ‎ 30 characters</td>
            </tr>
            <tr>
              <td>Descriptions</td>
              <td> ‎  ‎  ‎  ‎  ‎  ‎ 90 characters</td>
            </tr>
            <tr>
              <td>Sitelink text</td>
              <td> ‎  ‎  ‎  ‎  ‎  ‎ 25 characters</td>
            </tr>
            <tr>
              <td>Sitelink desc</td>
              <td> ‎  ‎  ‎  ‎  ‎  ‎ 35 characters</td>
            </tr>
            <tr>
              <td>Callouts</td>
              <td> ‎  ‎  ‎  ‎  ‎  ‎ 30 characters</td>
            </tr>
          </tbody>
        </table>
                    </div>
                    </div>
                    
                </div>

                <div class={`columnsz columnsz3 ${stepSixHalfCompleted ? 'fade-in' : ''} ${stepSixCompleted ? 'fade-out' : ''}`}>
                    <div class="columnonee">
                    <img class="smallimgg"src="/images/pdfloogo.png" alt="PDF Logo" />
                    </div>
                    
                    <div class="columntwoo">
                    <p>If you need further assistance</p>
                    <p class="secondpma"><a class="linknostyle" href="/images/Launch a Campaign in 60 seconds.pdf" target="_blank">Download</a> the walkthrough.</p>
                    </div></div>

                <div class={`stepseven ${stepSixCompleted ? 'fade-in' : ''}`}>
                            <p>For each of the titles in the sheet:</p>
                            <ul class="listoee">
    <li>1. ‎  Head to the title name</li>
    <li>2. ‎  Click on make multiple changes</li>
    <li>3. ‎  Paste and process</li>
    <li>4. ‎  Keep all changes</li>
</ul>
                </div>

            <button className={`btn stepfourbutton thisoneriht ${stepFiveCompleted ? 'hideelement' : ''}`} onClick={handleContinueToSix}>Next</button>
            <button className={`btn stepfourbutton thisoneriht thefifthstep stepfivebutton stepsixhalfbtn ${stepSixHalfCompleted ? 'fade-in' : ''} ${stepSixCompleted ? 'nodisplayimportant' : ''}`} onClick={handleReturn}>Finish</button>
            <button className={`btn stepfourbutton thisoneriht thefifthstep stepfivebutton ${stepFiveCompleted ? 'fade-in' : ''} ${stepSixHalfCompleted ? 'nodisplayimportant' : ''}`} onClick={handleContinueToSixHalf}>Next</button>
            <button className={`btn stepsevenbtn ${stepSixCompleted ? 'specialfade' : ''}`} onClick={handleReturn}>Return</button>
            <span class={`needmorehelp ${stepSixCompleted ? 'fade-in' : ''}`}>or visit the full import guide here</span>
            <div class={`thegif ${stepSixCompleted ? 'fade-in' : ''}`}>

            </div>
            </div>
<div class={`glowy ${stepOneCompleted ? 'glowfirst' : ''}`}>
            <div className="d-md-block d-none">
                     <BackdropAnimation glowClass={glowClass} delay={1.5} />
                  </div>
                  <div className="d-md-none d-block">
                     <BackdropGlow />
                  </div></div>
                  <AnimatedSection delay={0.2}>
            <div className={`desktop_mockup nomobile desktopmock ${isStarted ? 'started' : ''} ${stepThreeCompleted ? 'fade-out' : ''}`}>
                <div className="google_interface google_interface_desktop">

                    <div className="google_serach_result">
                        <div className="sponsored-text2">
                            <span>Sponsored</span>
                        </div>
                        <div className="serach_item">
                            <div className="brand_logo_img">
                                <img src="images/smalllogo.png" alt="" />
                            </div>
                            <div className="brand_info">
                                <div className="barnd_name">
                                    <h6 className={`m-0 ${mockupLoading ? 'loading-animation' : ''}`}>
                                        {mockup?.display_url
                                            ? mockup?.display_url
                                            : "ppcc.ai"}
                                    </h6>
                                    <div className="link">
                                        <p className={`website_link ${mockupLoading ? 'loading-animation' : ''}`}>
                                            {mockup?.url
                                                ? mockup?.url
                                                : "# "}
                                        </p>
                                        <a href="#" className="modal_dot">
                                            <i className="bx bx-dots-vertical-rounded"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="serach_result_main">
                            <a
                                href="#"
                                className=""

                                onclick="return false;"
                            >
                                <h1 className={`${mockupLoading ? 'loading-animation' : ''}`}>
                                    {mockup?.headline
                                        ? mockup?.headline
                                        : " PPCC - Generate Google Ads - Try for Free "}
                                </h1>
                            </a>
                            <p className={`info ${mockupLoading ? 'loading-animation' : ''}`}>
                                {mockup?.description
                                    ? mockup?.description
                                    : "extracts the most engaging, trending and impactful clips from your long-form videosis centered around machine learning capabilities, designed to keep what's important."}...
                            </p>
                        </div>
                        {mockup?.sitelinks?.length >= 0
                            ? mockup?.sitelinks?.map((item, index) => (
                                <div className={`Ktlw8e ${mockupLoading ? 'loading-animation' : ''}`} key={index}>
                                    <div className="iCzAIb">
                                        <div className="d-flx mock-site-link">
                                            <a href="#" className="" onclick="return false;">{item.title}</a>
                                            <div className="icon d-none-icon">
                                                <i className="bx bx-chevron-right"></i>
                                            </div>
                                        </div>
                                        <p className="info m-0">{item.description}</p>
                                    </div>
                                </div>
                            ))
                            : Mocksite?.map((item, index) => (
                                <div className={`Ktlw8e ${mockupLoading ? 'loading-animation' : ''}`} key={index}>
                                    <div className="iCzAIb">
                                        <div className="d-flx mock-site-link">
                                            <a className="" onclick="return false;">{item.site_text}</a>
                                            <div className="icon d-none-icon">
                                                <i className="bx bx-chevron-right"></i>
                                            </div>
                                        </div>
                                        <p className="info m-0">{item.sitePara}...</p>
                                    </div>
                                </div>
                            ))}


                    </div>
                </div>
            </div></AnimatedSection>
            
            <div className={`desktop_mockup mobile_mockup mobilemock ${stepTwoCompleted ? 'mobilemockupduringstepthree' : ''} ${stepThreeCompleted ? 'mobilemockupduringstepfour' : ''} `}>
                <div className="google_interface google_interface_mobile">
                    <div className="header_area_mobile">
                        <div className="header_ser_mobile">
                            <div className="serach_wapper">
                                <div className="google_logo_mobile">
                                    <img src="images/Google_2015_logo2.png" alt="" height="40px" />
                                </div>
                                <div className="gmail gmailmobile">
                                    <img src="images/smalllogo.png" alt="" />
                                </div>
                                <div className="google_serach google_serach_mobile">
                                    <div className="ser">

                                        <button href="#" className="goog_btn serch_ico serch_icomobile">
                                            <img src="images/Vector_search_icon.svg.png" alt="" />
                                        </button>

                                        <input className={`google_serach_input google_serach_input_mobile p-0 ${mockupLoading ? 'loading-animation' : ''}`}
                                            type="text"
                                            value={mockup.url ? formatUrl(mockup.url) : "brandname"}
                                        />
                                        <div className="google-search-close me-2">
                                            <svg
                                                focusable="false"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                            >
                                                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="end_area">
                                        <button href="#" className="goog_btn mic_mobile">
                                            <img src="images/Google_mic.svg" alt="" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="right ">
                                {/* <div className="search_labs">
                                             <img src="images/serachlabs.png" alt="" width="25px" />
                                          </div> */}

                            </div>
                        </div>
                        <div className="tabsmobile">
                            <div className="left_tabs_mobile">
                                <button href="#" className="tab_btn_mobile active">All</button>
                                <button href="#" className="tab_btn_mobile">Images</button>
                                <button href="#" className="tab_btn_mobile">Videos</button>
                                <button href="#" className="tab_btn_mobile">News</button>
                                <button href="#" className="tab_btn_mobile">Shopping</button>
                                <button href="#" className="tab_btn_mobile">Maps</button>
                                <button href="#" className="tab_btn_mobile">Books</button>
                                <button href="#" className="tab_btn_mobile d-none">
                                    <span>
                                        <i className="bx bx-dots-vertical-rounded"></i>
                                    </span>
                                    More
                                </button>
                            </div>
                            <div className="right_tabs d-none">
                                <button className="tab_btn ">Tool</button>
                            </div>
                        </div>
                    </div>
                    <div className="capsul_tab_mobile">
                        <button href="#" className="capsul_mobile">Free</button>
                        <button href="#" className="capsul_mobile">Reviews</button>
                        <button href="#" className="capsul_mobile">Open Now</button>
                        <button href="#" className="capsul_mobile">Clips</button>
                        <button href="#" className="capsul_mobile">Pricing</button>
                    </div>
                    <div className="google_serach_result_mobile">
                        <div className="sponsored-text2_mobile">
                            <span>Sponsored</span>
                        </div>
                        <div className="serach_item">
                            <div className="brand_logo_img brand_logo_img_mobile">
                                <img src="images/smalllogo.png" alt="" />
                            </div>
                            <div className="brand_info">
                                <div className="barnd_name_mobile">
                                    <h6 className={`m-0 ${mockupLoading ? 'loading-animation' : ''}`}>
                                        {mockup?.display_url
                                            ? mockup?.display_url
                                            : "yourwebsite.com"}
                                    </h6>
                                    <div className="link">
                                        <p className={`website_link_mobile ${mockupLoading ? 'loading-animation' : ''}`}>
                                            {mockup?.url
                                                ? mockup?.url
                                                : "#"}
                                        </p>
                                        <a href="#" className="modal_dot_mobile">
                                            <i className="bx bx-dots-vertical-rounded"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="serach_result_main_mobile">
                            <a
                                href="#"
                                className=""

                                onclick="return false;"
                            >
                                <h1 className={`${mockupLoading ? 'loading-animation' : ''}`}>
                                    {mockup?.headline
                                        ? mockup?.headline
                                        : " PPCC - Generate Google Ads - Try for Free "}
                                </h1>
                            </a>
                            {/* <span className="brand_name_highlisght">Munch</span> */}
                            <p className={`info_mobile ${mockupLoading ? 'loading-animation' : ''}`}>
                                {mockup?.description
                                    ? mockup?.description
                                    : "extracts the most engaging, trending and impactful clips from your long-form videosis centered around machine learning capabilities, designed to keep what's important."}...
                            </p>
                        </div>
                        {mockup?.sitelinks?.length >= 0
                            ? mockup?.sitelinks?.map((item, index) => (
                                <div className={`Ktlw8e_mobile ${index === Mocksite.length - 1 ? 'sitelinklast' : ''} ${mockupLoading ? 'loading-animation' : ''}`}>
                                    <div className="iCzAIb_mobile">
                                        <div className="d-flx mock-site-link">
                                            <a href="#" className="" onclick="return false;">{item.title}</a><span class="arrowsitelink">›</span>
                                            <div className="icon d-none-icon">
                                                <i className="bx bx-chevron-right"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                            : Mocksite?.map((item, index) => (
                                <div className={`Ktlw8e_mobile ${index === Mocksite.length - 1 ? 'sitelinklast' : ''} ${mockupLoading ? 'loading-animation' : ''}`}>
                                    <div className="iCzAIb_mobile">
                                        <div className="d-flx mock-site-link">
                                            <a className="" onclick="return false;">{item.site_text}</a>
                                            <div className="icon d-none-icon">
                                                <i className="bx bx-chevron-right"></i>
                                            </div>
                                        </div>
                                        <p className="info m-0">{item.sitePara}</p>
                                    </div>
                                </div>
                            ))}
                    </div>


                    {/* SECOND SEARCH RESULT */}
                    <div className="google_serach_result_mobile google_serach_result_mobile2">

                        <div className="serach_item">
                            <div className="brand_logo_img brand_logo_img_mobile">
                                <img src="images/smalllogo.png" alt="" />
                            </div>
                            <div className="brand_info">
                                <div className="barnd_name_mobile">
                                    <h6 className={`m-0 ${mockupLoading ? 'loading-animation' : ''}`}>
                                        {mockup?.display_url
                                            ? mockup?.display_url
                                            : "ppcc.ai"}
                                    </h6>
                                    <div className="link">
                                        <p className={`website_link_mobile ${mockupLoading ? 'loading-animation' : ''}`}>
                                            {mockup?.url
                                                ? mockup?.url
                                                : "# "}
                                        </p>
                                        <a href="#" className="modal_dot_mobile">
                                            <i className="bx bx-dots-vertical-rounded"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="serach_result_main_mobile">
                            <a
                                href="#"
                                className=""

                                onclick="return false;"
                            >
                                <h1 className={`${mockupLoading ? 'loading-animation' : ''}`}>
                                    {mockup?.headline
                                        ? mockup?.headline
                                        : " PPCC AI - Generate Google Ads - Try for Free "}
                                </h1>
                            </a>
                            {/* <span className="brand_name_highlisght">Munch</span> */}
                            <p className={`info_mobile info_mobile2 ${mockupLoading ? 'loading-animation' : ''}`}>
                                {mockup?.description
                                    ? mockup?.description
                                    : "extracts the most engaging, trending and impactful clips from your long-form videosis centered around machine learning capabilities, designed to keep what's important."}...
                            </p>
                            <div className={`snippetdivmobile ${mockupLoading ? 'loading-animation' : ''}`}><a href="#"><span class="snippetmobile">Your</span></a><a href="#"><span class="snippetmobile">Callouts</span></a><a href="#"><span class="snippetmobile">Here</span></a><a href="#"><span class="snippetmobile">Try and See for Free</span></a></div>
                        </div>


                    </div>



                </div>
            </div>

            </div>
            </div>

            

<div class="secbeforekeepscrolling text-center">
<motion.div style={{ scale: smoothScale, overflow: "visible", position: "relative" }}>
<div style={{ overflow: "visible", position: "relative" }}>
    <div class="topstuffhome">
                          <AnimatedSection delay={0.1}>
                               <div className="top-title mb-md-5 pb-1 mb-2 hdlzo">OUR QUEST</div>
                            </AnimatedSection>
                            
                            <AnimatedSection delay={0.4}>
                               <h1 className="h1">One-Minute PPC Campaigns that Perform.</h1>
                            </AnimatedSection>
                            <AnimatedSection delay={0.5}>
                               <div className="desc mb-4">
                                  <p class="p-18 generatep">Especially the "that perform" part sounds near impossible right? Just wait until you see this...</p>
                               </div>
                            </AnimatedSection>
                            
                            <div class="firststardiv">
<img className="starsfirst"src="/images/starsfirst.png"></img>
<span class="firststartext">+243 monthly users</span>
</div>
        </div>
        </div>
                      </motion.div>
                      <div class="secondtopstuff">
                      <section className="frame-image-sc space-mr">
                        
                      {/* MOCKUP  */}
                      <AnimatedSection>
                      <div className="outsideborderscreen">
            <div className="innerborderscreen">
                <img className="screenimagedash" src="/images/dashscreenn.png"/>
                <div className="screen-overlay" onClick={() => navigate("/creator")}>

  </div>
                </div>
                </div>
                      </AnimatedSection>
                      <div className="productuntdiv"><img className="productunt"src="/images/productunt.png"></img></div>
                      
                   </section>
        </div>
</div>
<div className="d-md-block d-none">
                      <BackdropAnimation glowClass={glowClass} delay={1.5} />
                   </div>
                   <div className="d-md-none d-block">
                      <BackdropGlow />
                   </div>
<div class="headsfloating ffweg">
  <span>Used by agencies and freelancers</span>
  

  <div class="marquee freelancers">
    <div class="marquee-content">

      <img class="identity" src="/images/achiel.png" alt="img1" />
      <img class="identity" src="/images/jef.png" alt="img2" />
      <img class="identity" src="/images/jef2.png" alt="img3" />
      <img class="identity" src="/images/jef3.png" alt="img4" />
      <img class="identity" src="/images/jef4.png" alt="img5" />
      <img class="identity" src="/images/jef5.png" alt="img6" />
      <img class="identity" src="/images/jef7.png" alt="img7" />
      <img class="identity" src="/images/jef8.png" alt="img8" />
      <img class="identity" src="/images/jef9.png" alt="img9" />
      <img class="identity" src="/images/jef10.png" alt="img10" />
      
      <img class="identity" src="/images/achiel.png" alt="img1" />
      <img class="identity" src="/images/sak.png" alt="img2" />
      <img class="identity" src="/images/jef2.png" alt="img3" />
      <img class="identity" src="/images/jef3.png" alt="img4" />
      <img class="identity" src="/images/jef4.png" alt="img5" />
      <img class="identity" src="/images/jef5.png" alt="img6" />
      <img class="identity" src="/images/jef7.png" alt="img7" />
      <img class="identity" src="/images/jef8.png" alt="img8" />
      <img class="identity" src="/images/jef9.png" alt="img9" />
      <img class="identity" src="/images/jef10.png" alt="img10" />
    </div>
  </div>
  
  <div class="marquee agencies">
    <div class="marquee-content">

    <img class="identity" src="/images/sak.png" alt="img1" />
      <img class="identity" src="/images/sak2.png" alt="img2" />
      <img class="identity" src="/images/sak3.png" alt="img3" />
      <img class="identity" src="/images/sak4.png" alt="img4" />
      <img class="identity" src="/images/sak5.png" alt="img5" />
      <img class="identity" src="/images/sak6.png" alt="img6" />
      <img class="identity" src="/images/sak7.png" alt="img7" />
      <img class="identity" src="/images/sak8.png" alt="img8" />
      <img class="identity" src="/images/sak9.png" alt="img9" />
      <img class="identity" src="/images/sak10.png" alt="img10" />
      
      <img class="identity" src="/images/sak.png" alt="img1" />
      <img class="identity" src="/images/sak2.png" alt="img2" />
      <img class="identity" src="/images/sak3.png" alt="img3" />
      <img class="identity" src="/images/sak4.png" alt="img4" />
      <img class="identity" src="/images/sak5.png" alt="img5" />
      <img class="identity" src="/images/sak6.png" alt="img6" />
      <img class="identity" src="/images/sak7.png" alt="img7" />
      <img class="identity" src="/images/sak8.png" alt="img8" />
      <img class="identity" src="/images/sak9.png" alt="img9" />
      <img class="identity" src="/images/sak10.png" alt="img10" />
    </div>
  </div>
</div>

                     <div class="keepscrollingdiv">
                        <div class="twocolumns">
                <AnimatedSection>
                <div class="steponesect cursorbehind">
                <h1>The Problem</h1>
                <div className="iconsnexttotitle">
<img className="problemsolutionicon" src="/images/googleeditor.png"/>
<img className="problemsolutionicon specialgpt" src="/images/gptlogo.png"/>
<img className="problemsolutionicon" src="/images/excelogo.png"/>
<img className="problemsolutionicon" src="/images/searchlogo.png"/>

</div>
<p><br/>Let's break it down:<br/><br/><br/>

<span class="whitetxt">One-minute</span><br/><br/>

Launching brand new PPC campaigns takes nowhere near that! There are plenty of boring phases you just have to go through.<br/><br/>

<span class="whitetxt">PPC Campaigns</span><br/><br/>

Have you heard of Google and Microsoft? That's what I'm talking about! Kinda like billboards within an iron box... Don't you think?<br/><br/>

<span class="whitetxt">that Perform</span><br/><br/>

A challenge at last! Which metrics drive conversion? You might want to check your finances after this one. We are about to blow your mind!</p>

                <img class="imagesteps stepthreeimg agiffor ffweg" src="/images/theproblem.gif" alt="ppc ads ai generate"></img>
                    </div>
                </AnimatedSection>
                <AnimatedSection delay={0.1}>
                <div class="steptwosect">
                <h1>The Solution</h1>
                <div className="iconsnexttotitle">
<img className="problemsolutionicon largerbecauseless" src="/images/ppclogowhite.png"/>

<img className="problemsolutionicon largerbecauseless" src="/images/googleeditor.png"/>
</div><br/>
<p>Here is the truth:<br/><br/><br/>

<span class="whitetxt">One-minute</span><br/><br/>

If you know what you are doing. First time using the Google search ads editor? The timing below might not apply to you.<br/><br/>

<span class="psicons"><img src="/images/ppclogowhite.png" class="minilogos"/>‎ 17-second download <span class="secondcheck"><img src="/images/googleeditor.png" class="minilogos"/>‎ 43-second launch</span></span><br/><br/>

<span class="whitetxt">that Perform</span><br/><br/>

Our performance formula? Community. And also, +24 customizable prompt templates used by other advertisers across the globe!<br/><br/>

<span class="psicons"><img src="/images/gptlogo.png" class="minilogos minilogos2"/>‎ +24 prompt templates <span class="secondcheck"><img src="/images/gptlogo.png" class="minilogos"/>‎ 100% customisable</span></span>
</p>
                <img class="imagesteps stepthreeimg agiffor ffweg" src="/images/thesolution.gif" alt="google ads editor"></img>
                </div>
                </AnimatedSection>
            </div>

            <h1 class="titleinsteps sectiontitlehome">Taking over</h1>
            <div class="fourcolumns">
            <Wrapper {...(!isMobile && { className: "nomobile" })}>
                <div class="steponesect sectt">
                <img class="imagesteps stepthreeimg" src="/images/searchlogo.png" alt="ppc ads ai generate"></img><br/>
                    <span>Step 1</span>
                    <h2 class="titleinsteps">Search</h2>
                    <p>"Do I really have to go through all of this?", the anwser is yes!</p>
                </div>
                </Wrapper>
                <Wrapper {...(!isMobile && { className: "nomobile", delay: 0.1 })}>
                <div class="steptwosect steptwosects sectt">
                <img class="imagesteps stepthreeimg specialgpt" src="/images/gptlogo.png" alt="google ads editor"></img><br/>
                <span>Step 2</span>
                    <h2 class="titleinsteps">Customize</h2>
                    <p>Client X wants search ads for page Y on keywords A, B and C.</p>
                </div>
                </Wrapper>
                <Wrapper {...(!isMobile && { className: "nomobile", delay: 0.2 })}>
                <div class="stepthreesect sectt">
                    <img class="imagesteps stepthreeimg" src="/images/excelogo.png" alt="google ads editor"></img><br/>
                <span>Step 3</span>
                    <h2 class="titleinsteps">Organize</h2>
                    <p>Your import-ready campaign sheet is almost the end result!</p>
                </div>
                </Wrapper>
                <Wrapper {...(!isMobile && { className: "nomobile", delay: 0.3 })}>
                <div class="stepthreesect stepfoursect sectt">
                    <img class="imagesteps stepthreeimg" src="/images/googleeditor.png" alt="google ads editor"></img><br/>
                <span>Step 4</span>
                    <h2 class="titleinsteps">Publish</h2>
                    <p>Not quite yet! Let's imagine launching PPC ads blind-folded.</p>
                </div>
                </Wrapper>
            </div>
            <img class="accoladeimg nomobile" src="/images/accolade.png"></img>
            <div class="accoladediv nomobile">
<div><img class="ppcreplaces" src="/images/ppclogowhite.png"/></div>
<div><span class="casualsidetext">Don't believe us? Try it for yourself:</span><br/>
</div>
<div class="testbtnim"><a href="/creator" target="_blank" class="btn testbtnecht">Visit the App</a></div>
</div>

<h1 className="titleinsteps sectiontitlehome">Screenshots</h1>
<div className="fourcolumns">
<Wrapper {...(!isMobile && { className: "nomobile" })}>
<div className="steponesect sectt sectnopad" onClick={() => openLightbox(0)}>
<img
className=""
src="/images/dashscreenn1.png"
onClick={() => openLightbox(0)}
alt=""
/>
</div>
</Wrapper>
<Wrapper {...(!isMobile && { className: "nomobile", delay: 0.1 })}>
<div className="steptwosect steptwosects sectt sectnopad">
<img
className=""
src="/images/creatordashboardscreen.png"
onClick={() => openLightbox(1)}
alt=""
/>
</div>
</Wrapper>
<Wrapper {...(!isMobile && { className: "nomobile", delay: 0.2 })}>
<div className="stepthreesect sectt sectnopad">
<img
className=""
src="/images/spotifyexcelscreen.png"
onClick={() => openLightbox(2)}
alt=""
/>
</div>
</Wrapper>
<Wrapper {...(!isMobile && { className: "nomobile", delay: 0.3 })}>
<div className="stepthreesect stepfoursect sectt sectnopad">
<img
className=""
src="/images/googleadseditorscreen.png"
onClick={() => openLightbox(3)}
alt=""
/>
</div>
</Wrapper>
</div>



            <h1 class="titleinsteps sectiontitlehome ffweg">Use cases</h1>
            <div class="fourcolumns ffweg">
            <Wrapper {...(!isMobile && { className: "nomobile" })}>
                <div class="steponesect sectt">
                <img class="imagesteps imagestepsother stepthreeimg" src="/images/mapslogo.png" alt="google ads editor"></img><br/>
                    <span>Local & brand</span>
                    <h3 class="titleinsteps">Campaigns</h3>
                    <p>Launch search ads for small business owners in seconds.</p>
                </div>
                </Wrapper>
                <Wrapper {...(!isMobile && { className: "nomobile", delay: 0.1 })}>
                <div class="steptwosect steptwosects sectt">
                <img class="imagesteps specialgpt imagestepsother stepthreeimg" src="/images/chinalogo.png" alt="google ads editor"></img><br/>

                <span>Campaigns that are</span>
                    <h3 class="titleinsteps">Multilingual</h3>
                    
                    <p>Download assets in any pre-language for any page.</p>
                </div>
                </Wrapper>
                <Wrapper {...(!isMobile && { className: "nomobile", delay: 0.2 })}>
                <div class="stepthreesect sectt">
                <img class="imagesteps imagestepsother stepthreeimg" src="/images/shopifylogo.png" alt="google ads editor"></img><br/>
                <span>For clients in</span>
                    <h3 class="titleinsteps">E-Commerce</h3>
                    <p>Scale search engine advertising for large product catalogs.</p>
                </div>
                </Wrapper>
                <Wrapper {...(!isMobile && { className: "nomobile", delay: 0.3 })}>
                <div class="stepthreesect stepfoursect sectt">
                <img class="imagesteps specialgpt imagestepsother stepthreeimg" src="/images/affiliatelogo.png" alt="google ads editor"></img><br/>
                <span>Promoting</span>
                    <h3 class="titleinsteps">Affiliate links</h3>
                    <p>PPC traffic generation in bulk using affiliate links.</p>
                </div>
                </Wrapper>
            </div>

            <h1 class="titleinsteps sectiontitlehome">All Functionalities</h1>
            <div class="fourcolumns">
    <Wrapper {...(!isMobile && { className: "nomobile" })}>
        <div class="steponesect sectt">
        <img class="imagesteps specialgpt imagestepsother stepthreeimg" src="/images/inputurl.png" alt="google ads editor"></img><br/>
        <span>Independent</span>
            <h3 class="titleinsteps">Deep search</h3>
            <p>Allow our AI to visit your website and scrape its content.</p>
        </div>
    </Wrapper>
    <Wrapper {...(!isMobile && { className: "nomobile", delay: 0.1 })}>
        <div class="steptwosect steptwosects sectt">
        <img class="imagesteps specialgpt imagestepsother stepthreeimg" src="/images/editable.png" alt="google ads editor"></img><br/>
        <span>100 percent</span>
            <h3 class="titleinsteps">Editable prompts</h3>
            <p>Customize the LLM instructions directly to enforce changes.</p>
        </div>
    </Wrapper>
    <Wrapper {...(!isMobile && { className: "nomobile", delay: 0.2 })}>
        <div class="stepthreesect sectt">
        <img class="imagesteps specialgpt imagestepsother stepthreeimg" src="/images/presets.png" alt="google ads editor"></img><br/>
        <span>Edit and save</span>
            <h3 class="titleinsteps">Prompt presets</h3>
            <p>Create reusable prompt sets for consistent copy generation.</p>
        </div>
    </Wrapper>
    <Wrapper {...(!isMobile && { className: "nomobile", delay: 0.3 })}>
        <div class="stepthreesect stepfoursect sectt">
        <img class="imagesteps specialgpt imagestepsother stepthreeimg" src="/images/clientsdata.png" alt="google ads editor"></img><br/>
        <span>Organize and</span>
            <h3 class="titleinsteps">Save client data</h3>
            <p>Store client templates for seamless organization.</p>
        </div>
    </Wrapper>
</div>

<div class="fourcolumnstwo">
<Wrapper {...(!isMobile && { className: "nomobile" })}>
        <div class="steponesect sectt">
        <img class="imagesteps specialgpt imagestepsother stepthreeimg" src="/images/limitschar.png" alt="google ads editor"></img><br/>
        <span>Marks exceeded</span>
            <h3 class="titleinsteps">Character limits</h3>
            <p>Highlights character limits exceeded, if any.</p>
        </div>
    </Wrapper>
    <Wrapper {...(!isMobile && { className: "nomobile", delay: 0.1 })}>
        <div class="steptwosect steptwosects sectt">
        <img class="imagesteps specialgpt imagestepsother stepthreeimg" src="/images/unique.png" alt="google ads editor"></img><br/>
        <span>Ad component</span>
            <h3 class="titleinsteps">Separation</h3>
            <p>Selectively generate only the needed ad components.</p>
        </div>
    </Wrapper>
    <Wrapper {...(!isMobile && { className: "nomobile", delay: 0.2 })}>
        <div class="stepthreesect sectt">
        <img class="imagesteps imagestepsother stepthreeimg" src="/images/shopifylogo.png" alt="google ads editor"></img><br/>
        <span>Two-minute</span>
            <h3 class="titleinsteps">Multi-URL campaigns</h3>
            <p>Launch campaigns across multiple URLs.</p>
        </div>
    </Wrapper>
    <Wrapper {...(!isMobile && { className: "nomobile", delay: 0.3 })}>
        <div class="stepthreesect stepfoursect sectt">
        <img class="imagesteps specialgpt imagestepsother stepthreeimg" src="/images/excelogo.png" alt="google ads editor"></img><br/>
        <span>Easily download an</span>
            <h3 class="titleinsteps">Importable sheet</h3>
            <p>To publish, copy-paste to the Google Ads Editor.</p>
        </div>
    </Wrapper>
</div>

<div class="fourcolumnstwo fourcolumnstwothird">
<Wrapper {...(!isMobile && { className: "nomobile" })}>
        <div class="steponesect sectt">
        <img class="imagesteps specialgpt imagestepsother stepthreeimg" src="/images/chinalogo.png" alt="google ads editor"></img><br/>
        <span>Select from</span>
            <h3 class="titleinsteps">+100 languages</h3>
            <p>Optionally set a Cialdini principle, and language.</p>
        </div>
    </Wrapper>
    <Wrapper {...(!isMobile && { className: "nomobile", delay: 0.1 })}>
        <div class="steptwosect steptwosects sectt">
        <img class="imagesteps specialgpt imagestepsother stepthreeimg" src="/images/mrcial.png" alt="google ads editor"></img><br/>
        <span>Integratable</span>
            <h3 class="titleinsteps">Cialdini's principles</h3>
            <p>Optionally select one of Cialdini's principles to consider.</p>
        </div>
    </Wrapper>
    <Wrapper {...(!isMobile && { className: "nomobile", delay: 0.2 })}>
        <div class="stepthreesect sectt">
        <img class="imagesteps specialgpt imagestepsother stepthreeimg" src="/images/searchlogo.png" alt="google ads editor"></img><br/>
        <span>Google search</span>
            <h3 class="titleinsteps">Mockup generation</h3>
            <p>Generate ad visualizations for your clients.</p>
        </div>
    </Wrapper>
    <Wrapper {...(!isMobile && { className: "nomobile", delay: 0.3 })}>
        <div class="stepthreesect stepfoursect sectt">
        <img class="imagesteps specialgpt imagestepsother stepthreeimg" src="/images/hourglass.png" alt="google ads editor"></img><br/>
        <span>Much more</span>
            <h3 class="titleinsteps">Coming soon</h3>
            <p>We are actively improving and adding functionalities.</p>
        </div>
    </Wrapper>
</div>

<h1 class="titleinsteps sectiontitlehome">Compare us</h1>
<div class="comparetablediv">
<table class="comparetable">
  <tr class="comparetablerow rowfirsttable">
    <td class="tablecd featurecd"><span class="scrollmetable">Scroll me →</span></td>
    <td class="tablecd">
      <img class="comparetableimage" src="/images/ppccaitool.png" />
    </td>
    <td class="tablecd">
      <img class="comparetableimage" src="/images/ppcadtool.png" />
    </td>
    <td class="tablecd">
      <img class="comparetableimage" src="/images/gpttool.png" />
    </td>
    <td class="tablecd">
      <img class="comparetableimage" src="/images/anywordtool.png" />
    </td>
    <td class="tablecd">
      <img class="comparetableimage" src="/images/adscaletool.png" />
    </td>
    <td class="tablecd">
      <img class="comparetableimage" src="/images/promonavitool.png" />
    </td>
    <td class="tablecd">
      <img class="comparetableimage" src="/images/optimyzrtool.png" />
    </td>
  </tr>

  <tr class="comparetablerow rowfirsttable">
    <td class="tablecd featurecd"><img className="iconintable"src="/images/linkicon.png"/><span class="textnexttotableicon">URL based AI generation</span></td>
    <td class="tablecd"><FaCheck color="#fff" size={20}/></td> 
    <td class="tablecd"></td> 
    <td class="tablecd"></td> 
    <td class="tablecd"></td> 
    <td class="tablecd"></td> 
    <td class="tablecd"></td> 
    <td class="tablecd"></td> 
  </tr>

  
  <tr class="comparetablerow rowfirsttable">
    <td class="tablecd featurecd"><img className="iconintable"src="/images/exporticon.png"/><span class="textnexttotableicon">Export for Google ads Editor</span></td>
    <td class="tablecd"><FaCheck color="#fff" size={20}/></td> 
    <td class="tablecd"></td>
    <td class="tablecd"></td>
    <td class="tablecd"></td>
    <td class="tablecd"></td>
    <td class="tablecd"></td>
    <td class="tablecd"></td>
  </tr>

  <tr class="comparetablerow rowfirsttable">
    <td class="tablecd featurecd"><img className="iconintable"src="/images/langicon.png"/><span class="textnexttotableicon">Multi-language support</span></td>
    <td class="tablecd"><FaCheck color="#fff" size={20}/></td>
    <td class="tablecd"></td>
    <td class="tablecd"><FaCheck color="#fff" size={20}/></td>
    <td class="tablecd"><FaCheck color="#fff" size={20}/></td>
    <td class="tablecd"></td>  
    <td class="tablecd"></td>
    <td class="tablecd"></td>
  </tr>

  <tr class="comparetablerow rowfirsttable">
    <td class="tablecd featurecd"><img className="iconintable"src="/images/prompticon.png"/><span class="textnexttotableicon">Prompt customization</span></td>
    <td class="tablecd"><FaCheck color="#fff" size={20}/></td>
    <td class="tablecd"></td>
    <td class="tablecd"><FaCheck color="#fff" size={20}/></td>
    <td class="tablecd"></td>
    <td class="tablecd"></td>
    <td class="tablecd"></td>
    <td class="tablecd"></td>
  </tr>

  <tr class="comparetablerow rowfirsttable">
    <td class="tablecd featurecd"><img className="iconintable"src="/images/dataicon.png"/><span class="textnexttotableicon">Save client data</span></td>
    <td class="tablecd"><FaCheck color="#fff" size={20}/></td>
    <td class="tablecd"><FaCheck color="#fff" size={20}/></td>
    <td class="tablecd"></td>
    <td class="tablecd"></td>
    <td class="tablecd"></td>
    <td class="tablecd"><FaCheck color="#fff" size={20}/></td>
    <td class="tablecd"><FaCheck color="#fff" size={20}/></td>
  </tr>

  <tr class="comparetablerow rowfirsttable">
    <td class="tablecd featurecd"><img className="iconintable"src="/images/limiticon.png"/><span class="textnexttotableicon">Character limit checking</span></td>
    <td class="tablecd"><FaCheck color="#fff" size={20}/></td>
    <td class="tablecd"><FaCheck color="#fff" size={20}/></td>
    <td class="tablecd"></td>
    <td class="tablecd"><FaCheck color="#fff" size={20}/></td>
    <td class="tablecd"></td>
    <td class="tablecd"></td>
    <td class="tablecd"><FaCheck color="#fff" size={20}/></td>
  </tr>

  <tr class="comparetablerow rowfirsttable">
    <td class="tablecd featurecd"><img className="iconintable"src="/images/visualicon.png"/><span class="textnexttotableicon">Visuals & ad mockups</span></td>
    <td class="tablecd"><FaCheck color="#fff" size={20}/></td>
    <td class="tablecd"><FaCheck color="#fff" size={20}/></td>
    <td class="tablecd"></td>
    <td class="tablecd"></td>
    <td class="tablecd"></td>
    <td class="tablecd"></td>
    <td class="tablecd"></td>
  </tr>

  <tr class="comparetablerow rowfirsttable">
    <td class="tablecd featurecd"><img className="iconintable"src="/images/multiicon.png"/><span class="textnexttotableicon">Multi-URL campaigns</span></td>
    <td class="tablecd"><FaCheck color="#fff" size={20}/></td>
    <td class="tablecd"><FaCheck color="#fff" size={20}/></td>
    <td class="tablecd"></td>
    <td class="tablecd"></td>
    <td class="tablecd"><FaCheck color="#fff" size={20}/></td>
    <td class="tablecd"><FaCheck color="#fff" size={20}/></td>
    <td class="tablecd"><FaCheck color="#fff" size={20}/></td>
  </tr>
</table>
</div>

<h1 class="titleinsteps sectiontitlehome">Options</h1>
<div class="threecolumns betasection">
            
                <Wrapper {...(!isMobile && { className: "nomobile", delay: 0.1 })}>
                <div class="steptwosect steptwosects sectt clickable" onClick={() => navigate("/mockupgen")}>
                
                <span>Choice 1</span>
                    <h2 class="titleinsteps">Generate Mockup</h2>
                    <p>Generate a Google search ads mockup with AI. Click here.</p>
                </div>
                </Wrapper>
                <Wrapper {...(!isMobile && { className: "nomobile", delay: 0.2 })}>
                <div class="stepthreesect sectt clickable" onClick={() => navigate("/campaigns")}>
                    
                <span>Choice 2</span>
                    <h2 class="titleinsteps">Generate a Sheet</h2>
                    <p>Download an import-ready campaign for any page. Click here.</p>
                </div>
                </Wrapper>
                <Wrapper {...(!isMobile && { className: "nomobile", delay: 0.3 })}>
                <div class="stepthreesect stepfoursect sectt clickable" onClick={() => navigate("/creator")}>
                    
                <span>Choice 3</span>
                    <h2 class="titleinsteps">Visit the App</h2>
                    <p>Try the all-in-one tool in it's unrestricted glory. Click here.</p>
                </div>
                </Wrapper>
            </div>




<div class="ffweg betasection"><h1 class="titleinsteps sectiontitlehome">Unlock Beta Access</h1>
<div class="twocolumnsspecial">
    <AnimatedSection>
        <div class="steponesect sectt specialfirstcolumn">
            <img class="productlogo" src="/images/ppclogowhite.png"/>
            <div className="twosubimages">
  <img
    className="screenshotimg firsttwoscreens"
    src="/images/dashboardscreenf.png"
    onClick={() => openLightbox(0)}
    alt=""
  />
  <img
    className="screenshotimg firsttwoscreens secondscreenshot"
    src="/images/dashboardscreenf.png"
    onClick={() => openLightbox(1)}
    alt=""
  />
</div>
<div className="subimages">
  <img
    className="screenshotimg threescreens"
    src="/images/dashboardscreenf.png"
    onClick={() => openLightbox(2)}
    alt=""
  />
  <img
    className="screenshotimg threescreens"
    src="/images/dashboardscreenf.png"
    onClick={() => openLightbox(3)}
    alt=""
  />
  <img
    className="screenshotimg threescreens"
    src="/images/dashboardscreenf.png"
    onClick={() => openLightbox(4)}
    alt=""
  />
</div>
            <div class="thumbnailbtns">
            <button class="btn btn-sq special-btn" onClick={() => openLightbox(0)}>See screenshots</button>
            <button class="btn btn-sq special-btn">Play launch video</button>
            </div>
        </div>
    </AnimatedSection>
    <AnimatedSection delay={0.1}>
        
        <div class="steponesect sectt specialsecondcolumn">
            <h3 class="titleinsteps titleinoffer">The PPC Campaign Creation Tool</h3>
            <div class="starssecond">
<img className="starsfirst"src="/images/starsfirst.png"></img>
<span class="firststartext">+243 monthly users</span>
</div>
            <p class="othertext">
            <br/>Create search campaigns in minutes (and replace $200-$3K in monthly costs).</p>
            
            <span class="usualpricing">$79.99</span><span class=" pricingdiscount"> $27.99</span><span class="permonth"> / month</span>
            <p class="offer-text"></p>
            <button class="btn special-btn btn-sq getstartedbtn">Get Started</button>
            <p class="guarantee-text">If you're not happy, we'll refund you — no questions asked.</p>
        </div>
        
    </AnimatedSection>
</div>
</div>

            </div>
            

            
            
            {lightboxOpen && (
  <Lightbox
    images={screenshots}
    currentIndex={lightboxIndex}
    onClose={() => setLightboxOpen(false)}
  />
)}
            </div>
    );
};
export default Homepage;
