import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

const Lightbox = ({ images, currentIndex, onClose }) => {
  const [index, setIndex] = useState(currentIndex);

  // Handle keyboard events: left/right arrows + close on Escape
  const handleKeyDown = (e) => {
    if (e.key === "ArrowLeft") {
      setIndex((prev) => (prev - 1 + images.length) % images.length);
    } else if (e.key === "ArrowRight") {
      setIndex((prev) => (prev + 1) % images.length);
    } else if (e.key === "Escape") {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [index]);

  // Close if user clicks anywhere on overlay but not the image or arrows
  const handleOverlayClick = (e) => {
    if (e.target.className.includes("lightbox-overlay")) {
      onClose();
    }
  };

  // The lightbox JSX
  const lightboxContent = (
    <div className="lightbox-overlay" onClick={handleOverlayClick}>
      <button
        className="arrow left"
        onClick={(e) => {
          e.stopPropagation();
          setIndex((index - 1 + images.length) % images.length);
        }}
      >
        ‹
      </button>
      <img
        src={images[index]}
        alt="Large preview"
        onClick={(e) => e.stopPropagation()}
      />
      <button
        className="arrow right"
        onClick={(e) => {
          e.stopPropagation();
          setIndex((index + 1) % images.length);
        }}
      >
        ›
      </button>
    </div>
  );

  // Render via React Portal to the top-level DOM
  return ReactDOM.createPortal(lightboxContent, document.body);
};

export default Lightbox;
