import React, { useEffect, useRef, useState } from "react";
import "../Style/creator.css";
import AnimatedSection from "../Components/AnimatedSection";
import "../Style/generatetool.css";
import { AuthFetch, AuthFetch2, refresh, signUpWithGoogle, signUpWithMicrosoft } from "../lib/auth";
import useAuth from "../hooks/useAuth";
import BackdropAnimation from "../Components/BackdropAnimation";
import BackdropGlow from "../Components/BackdropGlow";
import { useUser } from "../contexts/userContext";
import { useNavigate } from "react-router-dom";

 
 const Creator = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
const [isLandscape, setIsLandscape] = useState(window.matchMedia("(orientation: landscape)").matches);

useEffect(() => {
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
    setIsLandscape(window.matchMedia("(orientation: landscape)").matches);
  };

  window.addEventListener("resize", handleResize);
  window.addEventListener("orientationchange", handleResize);

  return () => {
    window.removeEventListener("resize", handleResize);
    window.removeEventListener("orientationchange", handleResize);
  };
}, []);

  const isAuthenticated = useAuth();
   const navigate = useNavigate()
    const [url, setUrl] = useState('');
    const [stepTwoCompleted, setStepTwoCompleted] = useState(false);
  const [stepThreeCompleted, setStepThreeCompleted] = useState(false);
  const [mockup, setMockup] = useState({
    url: "https://www.brandname.com",
    headline: "Your Brandname - Enter Your URL Below",
    description: "Enter a Website and instantly Generate PPC search ads. Experience how Easy it is to Launch Ads with AI—no expertise required.",
    display_url: "yourwebsite.com",
    sitelinks: [
      { title: "Try for Yourself", description: "Generate a PPC ad for your website now." },
      { title: "It's Completely Free", description: "See how AI makes ad creation effortless." },
      { title: "Your Page here", description: "Let's customize your journey with us." },
      { title: "Launch in 5 Min", description: "Try for yourself by entering a URL above." },
    ],
  });
  const [mockupLoading, setMockupLoading] = useState(false);

  const [clientData, setClientData] = useState("NaN");

  // Helper function
  const formatUrl = (url) => {
    let formattedUrl = url.replace(/(^\w+:|^)\/\//, '');
    formattedUrl = formattedUrl.replace(/^www\./, '');
    return formattedUrl.split('.')[0];
  };

  // Fallback array
  const Mocksite = [
    { site_text: "Try for Yourself", sitePara: "Generate a PPC ad for your website now." },
    { site_text: "It's Completely Free", sitePara: "See how AI makes ad creation effortless." },
    { site_text: "Your Page here", sitePara: "Let's customize your journey with us." },
    { site_text: "Launch in 5 Minutes", sitePara: "Try for yourself by entering a URL above." },
  ];
  
  const [isOpen, setIsOpen] = useState(false);
  const [label, setLabel] = useState("unsaved");
  const [isEditing, setIsEditing] = useState(false);

  // Refs for the button area and dropdown.
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  // Toggle dropdown visibility if not in editing mode.
  const toggleDropdownn = () => {
    if (!isEditing) {
      setIsOpen((prev) => !prev);
    }
  };

  // When a dropdown item is clicked, update the label and close the dropdown.
  const handleSelectItem = (item) => {
    setLabel(item);
    setIsOpen(false);
  };

  // Enable editing mode on double-click.
  const handleDoubleClick = () => {
    setIsEditing(true);
    // Focus the input after it appears.
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  };

  // When the input loses focus, exit editing mode.
  const handleInputBlur = (e) => {
    // Optionally, update the label with the current input value.
    setLabel(e.target.value);
    setIsEditing(false);
  };

  // Also exit editing mode on Enter key and update the label.
  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      setLabel(event.target.value);
      setIsEditing(false);
    }
  };


// State & refs for Prompt Dropdown
const [promptLabel, setPromptLabel] = useState("Standard");
const [promptIsOpen, setPromptIsOpen] = useState(false);
const [promptIsEditing, setPromptIsEditing] = useState(false);

const promptButtonRef = useRef(null);
const promptDropdownRef = useRef(null);
const promptInputRef = useRef(null);
// Toggle prompt dropdown visibility (only when not editing)
const togglePromptDropdown = () => {
    if (!promptIsEditing) {
      setPromptIsOpen((prev) => !prev);
    }
  };
  
  // Handle prompt selection
  const handlePromptSelectItem = (item) => {
    setPromptLabel(item);
    setPromptIsOpen(false);
  };
  
  // Enable editing mode on double-click
  const handlePromptDoubleClick = () => {
    setPromptIsEditing(true);
    setTimeout(() => {
      if (promptInputRef.current) {
        promptInputRef.current.focus();
      }
    }, 0);
  };
  
  // Exit editing mode when input loses focus or Enter is pressed
  const handlePromptInputBlur = (e) => {
    setPromptLabel(e.target.value);
    setPromptIsEditing(false);
  };
  
  const handlePromptInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      setPromptLabel(e.target.value);
      setPromptIsEditing(false);
    }
  };
  useEffect(() => {
    const handleClickOutsidePrompt = (event) => {
      if (
        promptDropdownRef.current &&
        !promptDropdownRef.current.contains(event.target) &&
        promptButtonRef.current &&
        !promptButtonRef.current.contains(event.target)
      ) {
        setPromptIsOpen(false);
      }
    };
  
    document.addEventListener('click', handleClickOutsidePrompt);
    return () => document.removeEventListener('click', handleClickOutsidePrompt);
  }, []);





// ----- New: Operational Dropdowns for Language & Cialdini Principle -----
const [selectedLanguage, setSelectedLanguage] = useState("English");
const [languageSelected, setLanguageSelected] = useState(false);
const [principle, setPrinciple] = useState("None");
const [principleSelected, setPrincipleSelected] = useState(false);
const [dropdownOpen, setDropdownOpen] = useState({ campaign: false, principle: false });

const getDisplayLanguage = () => {
  if (!languageSelected && selectedLanguage === "English") {
    return "Language";
  }
  return selectedLanguage;
};

const getDisplayPrinciple = () => {
  if (!principleSelected && principle === "None") {
    return "Cialdini Principle";
  }
  return principle;
};

const toggleDropdown = (type) => {
  setDropdownOpen((prev) => ({
    campaign: type === "campaign" ? !prev.campaign : false,
    principle: type === "principle" ? !prev.principle : false,
  }));
};

const handleOptionSelect = (type, value, event) => {
  event.stopPropagation();
  if (type === "campaign") {
    setSelectedLanguage(value);
    setLanguageSelected(true);
  } else if (type === "principle") {
    setPrinciple(value);
    setPrincipleSelected(true);
  }
  setDropdownOpen((prev) => ({
    ...prev,
    [type]: false,
  }));
};

useEffect(() => {
  const handleClickOutside = (event) => {
    if (
      !event.target.closest(".custom-dropdown.selectcm") &&
      !event.target.closest(".custom-dropdown.selectcp")
    ) {
      setDropdownOpen({ campaign: false, principle: false });
    }
  };

  document.addEventListener("click", handleClickOutside);
  return () => document.removeEventListener("click", handleClickOutside);
}, []);

// ----- New: Numeric Inputs for Ads & Keywords -----
const [numberOfAds, setNumberOfAds] = useState(1);
const [numberOfKeywords, setNumberOfKeywords] = useState(5);


const maxInstructionsLength = 2000;
  const [campaignData, setCampaignData] = useState({
    "Brand name campaign": {
      menuItems: {
        Headlines:
          "Please ensure that each headline meets the following criteria:\n\nMaximum of 30 characters per headline.\nAll words should start with a capital letter for readability, except for less meaningful connecting words like 'and,' 'for,' or 'about.'\nGenerate the headlines in a table format with 12 columns and 2 rows for easy copy-pasting.\nAim for a high click-through rate (CTR).\nKeep the language simple, concise, and easy to understand.\nDo not generate anything other than the table.\n\nRemember:\nHeadlines should be engaging and relevant.\nPrioritize clarity and call-to-action to encourage users to click.\nStick to the 30-character limit per headline.\nEnsure all words follow the capitalization rule (except connecting words).",
        Descriptions:
          "The descriptions should be concise, engaging, and based on the scraped data from the website. When writing these, keep in mind the following:\n\nEach description should clearly communicate the product's value or key features.\nStart all words with a capital letter for readability, except for connecting words like 'and,'' 'for,'' or 'about.'\nAim for descriptions that will encourage a high click-through rate (CTR).\nProvide 4 descriptions.",
        Sitelinks:
          "Each sitelink should include the following:\n\nSitelink name: Use standard yet relevant names for common pages.\nFinal URL: Provide a relevant URL for each sitelink.\nDescription line 1: Four to five words. Never more than seven words.\nDescription line 2: Four to five words. Never more than seven words.\nMake sure the descriptions effectively communicate the value of the linked pages.",
        Callouts:
          "I need a total of 4 callouts. When writing these, keep in mind the following:\n\nUse the content extracted from the provided website content to inform your callouts.\nEach callout should be concise, engaging, and highlight key benefits or unique selling points of the product or service.\nEnsure the callouts are relevant to the website content and encourage clicks.\nKeep the amount of characters per callout 5-20: keep callouts concise.",
        Snippets:
          "I want you to write a total of 8 snippets, organized into 2 headers.\n\nWhen writing these, keep in mind the following:\n\nResearch the provided website URL and extract relevant information to create the snippets.\nFor the 2 headers, always choose one from the following 13 options: Amenities, Brands, Courses, Degree Programs, Destinations, Featured Hotels, Insurance Coverage, Models, Neighborhoods, Service Catalog, Shows, Styles, Types.\nUnder each header, list 4 snippet values without any additional formatting or characters.",
        Keywords:
          "\n\n\n\n\n\n\n\n\n\n\n ‎  ‎  ‎  ‎  ‎  ‎ ‎  ‎  ‎  ‎ ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎ ‎  ‎ Coming soon"
      },
    },
  });
  const [campaignMode] = useState("Brand name campaign");
  const [selectedMenu, setSelectedMenu] = useState("Headlines");

  const handleInstructionsChange = (e) => {
    const newInstructions = e.target.value;
    if (newInstructions.length <= maxInstructionsLength) {
      setCampaignData((prevData) => ({
        ...prevData,
        [campaignMode]: {
          ...prevData[campaignMode],
          menuItems: {
            ...prevData[campaignMode].menuItems,
            [selectedMenu]: newInstructions,
          },
        },
      }));
    }
  };


// --- Optional: Checked items (if you need them for extraction) ---
const [checkedItems] = useState({
    headline: true,
    descriptions: true,
    sitelinks: true,
    callout: true,
    snippets: true,
  });

  // --- New State for Extraction Loading / Success ---
  const [extractLoading, setExtractLoading] = useState(false);
  const [extracted, setExtracted] = useState(false);
  const [totalCost, setTotalCost] = useState(250);
  const { user } = useUser();

  // --- Helper Function for Special Instructions ---
  const getValidInstruction = (field) => field.trim() === "" ? null : field;

// mockup gen
const handleGenerateMockup = async (e) => {
    e.preventDefault();
    if (!url) {
      alert("Please enter a valid URL");
      return;
    }
    
    
    const formattedUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
  
      try {
        setMockupLoading(true);
        const response = await AuthFetch2('campaign-gen/mockupGen', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                url: formattedUrl
            })
        });

        const data = await response.json();
        if (data?.message) {
            setMockupLoading(false);
            return alert(data?.message);
        }

        setMockup(data?.mockup);
        setMockupLoading(false);
    // GA Event for Mockup generation success
    if (typeof window !== 'undefined' && typeof window.gtag === 'function') {
      window.gtag('event', 'mockup', {
        event_category: 'Generation',
        event_label: formattedUrl,
      });
      console.log("GA4 mockup event fired");
    }

  } catch (error) {
    console.log(error);
    setMockupLoading(false);
  }
};

const handleExtract = async () => {
  if (!isAuthenticated) return navigate('/login');
  if (!url) return alert('Please fill valid url');
  const formattedUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;

  setExtractLoading(true);
  const accessToken = localStorage.getItem('accessToken')
  const { headline, descriptions, sitelinks, callout, snippets } = checkedItems
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/campaign-gen/extract`, {
     method: 'POST',
     headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
     },
     body: JSON.stringify({
        url: formattedUrl, headline, descriptions,
        sitelinks, callout, snippets,
        count: numberOfAds, campaignMode, cialdini: principle === "Cialdini's principle" ? "none" : principle,
        countk: numberOfKeywords,
        clientdata: clientData,
        specialInstructionsHeadlines: getValidInstruction(
           campaignMode === "Brand name campaign"
              ? campaignData["Brand name campaign"].menuItems.Headlines
              : campaignData["Broad search campaign"].menuItems.Headlines
        ),
        specialInstructionsDescriptions: getValidInstruction(
           campaignMode === "Brand name campaign"
              ? campaignData["Brand name campaign"].menuItems.Descriptions
              : campaignData["Broad search campaign"].menuItems.Descriptions
        ),
        specialInstructionsSitelinks: getValidInstruction(
           campaignMode === "Brand name campaign"
              ? campaignData["Brand name campaign"].menuItems.Sitelinks
              : campaignData["Broad search campaign"].menuItems.Sitelinks
        ),
        specialInstructionsSnippets: getValidInstruction(
           campaignMode === "Brand name campaign"
              ? campaignData["Brand name campaign"].menuItems.Snippets
              : campaignData["Broad search campaign"].menuItems.Snippets
        ),
        specialInstructionsCallouts: getValidInstruction(
           campaignMode === "Brand name campaign"
              ? campaignData["Brand name campaign"].menuItems.Callouts
              : campaignData["Broad search campaign"].menuItems.Callouts
        ),
        specialInstructionsKeywords: getValidInstruction(
           campaignMode === "Brand name campaign"
              ? campaignData["Brand name campaign"].menuItems.Keywords
              : campaignData["Broad search campaign"].menuItems.Keywords
        ),
        language: selectedLanguage
     })
  });

  if (response.ok) {
     const blob = await response.blob();
     const url = window.URL.createObjectURL(blob);

     // Create an anchor element and trigger a download
     const a = document.createElement('a');
     a.href = url;
     a.download = 'campaign_template.xlsx'; // Set the filename for download
     document.body.appendChild(a);
     a.click();

     // Cleanup: Remove the link element and revoke the blob URL
     a.remove();
     window.URL.revokeObjectURL(url);
     setExtractLoading(false)

   // GA Event for successful extraction
    if (typeof window !== 'undefined' && typeof window.gtag === 'function') {
      window.gtag('event', 'extract', {
        event_category: 'Generation',
        event_label: formattedUrl,
        ads_count: numberOfAds,
        keywords_count: numberOfKeywords,
      });
     console.log("GA4 extract event fired");
    }

  } else if (response.status === 401) {
     const { accessToken: newAccessToken } = await refresh();
     const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/campaign-gen/extract`, {
        method: 'POST',
        headers: {
           'Content-Type': 'application/json',
           'Authorization': `Bearer ${newAccessToken}`
        },
        body: JSON.stringify({
           url, headline, descriptions, sitelinks, callout, snippets
        })
     });

     if (res.ok) {
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);

        // Create an anchor element and trigger a download
        const a = document.createElement('a');
        a.href = url;
        a.download = 'campaign_template.xlsx'; // Set the filename for download
        document.body.appendChild(a);
        a.click();

        // Cleanup: Remove the link element and revoke the blob URL
        a.remove();
        window.URL.revokeObjectURL(url);
        setExtractLoading(false)
     } else {
        const data = await response.json();
        alert(data.message);
        setExtractLoading(false)
     }
  } else {
     const data = await response.json();
     alert(data?.message)
     setExtractLoading(false)
  }

}

const glowClass =
      campaignMode === "Broad search campaign"
         ? "backdrop-elm-white"
         : campaignMode === "Brand name campaign"
            ? "backdrop-elm-white"
            : "backdrop-elm-white";
   const campaignModes = [
      "Brand name campaign",
      "Broad search campaign",
   ];

  // Close the dropdown when clicking outside the button or dropdown.
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  // GA sign up/in with Google or microsoft 
   useEffect(() => {
  if (typeof window !== 'undefined') {
    const oauthMethod = localStorage.getItem('oauthLogin');
    if (oauthMethod) {
      console.log("GA4 oauth_auth event fired")
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'oauth_auth', {
          method: oauthMethod,
          event_category: 'Authentication',
          event_label: 'OAuth Authentication Success'
        });
      }
      localStorage.removeItem('oauthLogin'); // Prevent repeat
    }
  }
}, []);

    useEffect(() => {
  document.title = "Create High-Performance PPC Campaigns with AI | PPCC.ai";

  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute("content", "Generate fully customizable PPC campaigns with AI. Save client data, set languages, and export Google Ads-ready sheets.");
  }

  const metaKeywords = document.querySelector('meta[name="keywords"]');
  if (metaKeywords) {
    metaKeywords.setAttribute("content", "PPC automation, AI PPC campaigns, Google Ads AI, search ad optimization, PPCC.ai");
  }

  // Open Graph tags
  const ogTitle = document.querySelector('meta[property="og:title"]');
  if (ogTitle) {
    ogTitle.setAttribute("content", "Create High-Performance PPC Campaigns with AI | PPCC.ai");
  }

  const ogDescription = document.querySelector('meta[property="og:description"]');
  if (ogDescription) {
    ogDescription.setAttribute("content", "Generate fully customizable PPC campaigns with AI. Save client data, set languages, and export Google Ads-ready sheets.");
  }

  const ogImage = document.querySelector('meta[property="og:image"]');
  if (ogImage) {
    ogImage.setAttribute("content", "URL_to_creator_image");
  }

  const ogUrl = document.querySelector('meta[property="og:url"]');
  if (ogUrl) {
    ogUrl.setAttribute("content", "https://www.ppcc.ai/creator");
  }

  // Twitter Card tags
  const twitterTitle = document.querySelector('meta[name="twitter:title"]');
  if (twitterTitle) {
    twitterTitle.setAttribute("content", "Create High-Performance PPC Campaigns with AI | PPCC.ai");
  }

  const twitterDescription = document.querySelector('meta[name="twitter:description"]');
  if (twitterDescription) {
    twitterDescription.setAttribute("content", "Generate fully customizable PPC campaigns with AI. Optimize and export Google Ads-ready sheets.");
  }

  const twitterImage = document.querySelector('meta[name="twitter:image"]');
  if (twitterImage) {
    twitterImage.setAttribute("content", "URL_to_creator_image");
  }

  const twitterCard = document.querySelector('meta[name="twitter:card"]');
  if (twitterCard) {
    twitterCard.setAttribute("content", "summary_large_image");
  }

}, []);
const signOut = () => {
  localStorage.clear();
  window.location.reload();
}
    
if (isMobile && !isLandscape) {
      return (
        <div style={{ 
          display: "flex", 
          flexDirection: "column", 
          alignItems: "center", 
          justifyContent: "center", 
          height: "100vh" 
        }}>
          
          
          <div style={{ textAlign: "center", marginTop: "2rem" }}>
            <img src="/images/rotatephone.gif" className="rotatephonegif"/>
            <br/><br/>
            <p style={{color: "#555" }}>
              Rotate your phone to view the App<br/><br/><br/> ‎ <br/> ‎
            </p>
          </div>
        </div>
      );
    }

return (
    <div className="main">

      <div class="z-indexx">

        <div className="centeredmain">
          <img className="leftopcorner  largerbecauseless logotopleft" src="/images/ppclogowhite.png"/>



          {!isAuthenticated ? (
          <span className="rightopcorner">
          <a className="rightmenutop " href="/freecredits">Pricing</a>
          <a className="rightmenutop " href="/login">Login</a>
          <a className="rightmenutop " href="/signup">Signup</a>
          </span>
        ) : (
<span className="rightopcorner">
          <a className="rightmenutop " href="/freecredits">Credits left: {user?.credits?.toLocaleString('de-DE')}</a>
          
          <a className="rightmenutop logoutclass" onClick={signOut}>Logout</a>
          </span>
        )}

          

            <div className="threesectionsdashboard">
              
            <div className="clientdata">
                <span className="titledashboardsec">Client data</span>
                <div className="tooltip-container">
                                                                   <span className="question-mark question-mark1">?</span>
                                                                   <div className="tooltip-text tooltip-text1">
                                                                      <span className="tool-tip-title">Pro tip:</span>
                                                                      <br/>Use this section to paste landing page content for smaller or restricted websites that cannot be visited by AI.
                                                                   </div>
                                                                </div>
                
                {isEditing ? (
  <input 
  className="presetdisplayinput"
    ref={inputRef} 
    type="text" 
    defaultValue={label} 
    onBlur={handleInputBlur} 
    onKeyDown={handleInputKeyDown} 
    style={{ fontSize: 'inherit' }}
  />
) : (
  <span 
    ref={buttonRef} 
    className="presetdisplay" 
    onClick={toggleDropdownn}
    onDoubleClick={handleDoubleClick}
    style={{ cursor: 'pointer' }}
  >
    {label}
  </span>
)}

{isOpen && (
  <div ref={dropdownRef} className="dropdown-content">
    <a className="dropdownitemm" href="#" onClick={() => handleSelectItem("untitled")}>+ create new</a>
    <a className="dropdownitemm" href="#" onClick={() => handleSelectItem("untitled")}>coming soon</a>
    <a className="dropdownitemm" href="#" onClick={() => handleSelectItem("untitled")}>coming soon</a>
    <a className="dropdownitemm" href="#" onClick={() => handleSelectItem("untitled")}>coming soon</a>
    <a className="dropdownitemm" href="#" onClick={() => handleSelectItem("untitled")}>coming soon</a>
    <a className="dropdownitemm" href="#" onClick={() => handleSelectItem("untitled")}>coming soon</a>
  </div>
)}


                <div className="dashboardboxouter"><div className="dashboardboxinner">
                    <textarea className="clientdatainput"type="text" value={clientData} onChange={(e) => setClientData(e.target.value)}></textarea>
                </div>
                </div>
            </div>
            <div className="urlbox">
                <br/><span className="titledashboardsec">Final URL 
                <span className="smallertitledashboard">(switch to multi)</span>
                </span>
                <div className="dashboardboxouter"><div className="dashboardboxinner dashboardboxinnersmall">
                <form class="d-md-flex justify-content-center align-items-center generateform" onSubmit={handleGenerateMockup}><input
  className="urldatainput "
  type="text"
  autoComplete="url"
  placeholder="https://www.yourwebsite.com/page"
  value={url}
  onChange={(e) => setUrl(e.target.value)}
  
  
/></form><span class="enterbtnarrowurl" onClick={handleGenerateMockup}>→</span>
                    
                </div></div>
                </div>
            
                <div className="dashboardbox campaignsettings">
            <br />
            <span className="titledashboardsec">Campaign Settings</span>
            <div className="dashboardboxouter">
              <div className="dashboardboxinner campaignsettingsbox">
                {/* Language & Principle Dropdowns */}
                <div className="operational-dropdowns dashboardlcp">
                  <div
                    className="custom-dropdown selectcm generateselect dashboardlanguage"
                    onClick={() => toggleDropdown("campaign")}
                  >
                    <div className="custom-dropdown-selected">
                      {getDisplayLanguage()}
                      <svg
                        className="custom-dropdown-chevron"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="16"
                        height="16"
                      >
                        <path fill="white" d="M7 10l5 5 5-5z" />
                      </svg>
                    </div>
                    {dropdownOpen.campaign && (
                      <ul className="custom-dropdown-options custom-dropdown-optionsc dropeddownl">
                        {["English", "Spanish", "French", "Dutch", "German"].map((language) => (
                          <li
                            key={language}
                            className="custom-dropdown-option custom-dropdown-optionc"
                            onClick={(e) => handleOptionSelect("campaign", language, e)}
                          >
                            {language}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  <div
                    className="custom-dropdown selectcp generateselect dashboardcialdini"
                    onClick={() => toggleDropdown("principle")}
                  >
                    <div className="custom-dropdown-selected">
                      {getDisplayPrinciple()}
                      <svg
                        className="custom-dropdown-chevron"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="16"
                        height="16"
                      >
                        <path fill="white" d="M7 10l5 5 5-5z" />
                      </svg>
                    </div>
                    {dropdownOpen.principle && (
                      <ul className="custom-dropdown-options custom-dropdown-optionsc dropeddownc">
                        {[
                          "Reciprocity",
                          "Consistency",
                          "Social Proof",
                          "Authority",
                          "Liking",
                          "Scarcity",
                          "None",
                        ].map((option) => (
                          <li
                            key={option}
                            className="custom-dropdown-option"
                            onClick={(e) => handleOptionSelect("principle", option, e)}
                          >
                            {option}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>

                {/* Numeric Inputs for Amount of Ads and Keywords */}
                <div className="operational-numeric-inputs">
                  <div className="amount-of-ads dashboardamount">
                    <label htmlFor="number_of_ads">Amount of ads</label>
                    <button className="minusbtndashboard"
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => setNumberOfAds((prev) => Math.max(1, prev - 1))}
                    >
                      -
                    </button>
                    <input className="dashboardamountinput"
                      type="number"
                      id="number_of_ads"
                      value={numberOfAds}
                      onChange={(e) => setNumberOfAds(Math.max(1, Number(e.target.value) || 1))}
                      min="1"
                      max="5"
                    />
                    <button className="plusbtndashboard"
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => setNumberOfAds((prev) => Math.min(5, prev + 1))}
                    >
                      +
                    </button>
                  </div>
                  <div className="amount-of-keywords dashboardamount dashboardamountk ffweg">
                    <label htmlFor="number_of_keywords">Amount of keywords</label>
                    <button className="minusbtndashboard"
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => setNumberOfKeywords((prev) => Math.max(5, prev - 5))}
                    >
                      -
                    </button>
                    <input
                      type="number"
                      className="dashboardamountinput"
                      id="number_of_keywords"
                      value={numberOfKeywords}
                      onChange={(e) => setNumberOfKeywords(Math.max(5, Number(e.target.value) || 5))}
                      min="5"
                      max="20"
                    />
                    
                    <button className="plusbtndashboard"
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => setNumberOfKeywords((prev) => Math.min(20, prev + 5))}
                    >
                      +
                    </button>

                  </div>
                </div>
              </div>
            </div>
          </div>

            <div className="dashboardlargebox promptsdashboard">
            <br/><br/><span className="titledashboardsec">Prompts</span>

            {promptIsEditing ? (
  <input
    className="presetdisplayinput"
    ref={promptInputRef}
    type="text"
    defaultValue={promptLabel}
    onBlur={handlePromptInputBlur}
    onKeyDown={handlePromptInputKeyDown}
    style={{ fontSize: 'inherit' }}
  />
) : (
  <span
    ref={promptButtonRef}
    className="presetdisplay"
    onClick={togglePromptDropdown}
    onDoubleClick={handlePromptDoubleClick}
    style={{ cursor: 'pointer' }}
  >
    {promptLabel}
  </span>
)}

{promptIsOpen && (
  <div ref={promptDropdownRef} className="dropdown-content dropdown-contentprompts">
    <a className="dropdownitemm" href="#" onClick={() => handlePromptSelectItem("Standard")}>
      Standard
    </a>
    <a className="dropdownitemm" href="#" onClick={() => handlePromptSelectItem("Standard")}>
    coming soon
    </a>
    <a className="dropdownitemm" href="#" onClick={() => handlePromptSelectItem("Standard")}>
    coming soon
    </a>
    <a className="dropdownitemm" href="#" onClick={() => handlePromptSelectItem("Standard")}>
    coming soon
    </a>
  </div>
)}

            <div className="dashboardboxouter"><div className="dashboardboxinner dashboardboxinnerlarge">
            <div className="menuprompts">
                  {["Headlines", "Descriptions", "Sitelinks", "Callouts", "Snippets", "Keywords"].map(
                    (item) => (
                      <span
                        key={item}
                        className={`promptinmenu ${
                          selectedMenu === item ? "selectedprompt" : ""
                        }`}
                        onClick={() => setSelectedMenu(item)}
                      >
                        {item}
                      </span>
                    )
                  )}
                </div>
                <textarea
                  className="promptdatainput"
                  value={
                    campaignData[campaignMode].menuItems[selectedMenu] || ""
                  }
                  onChange={handleInstructionsChange}
                  placeholder={`Leave empty to skip ${selectedMenu}`}
                ></textarea>
                </div></div>
            </div>
            <div className="mockupsectionn">
            
            <div className={`desktop_mockup mobile_mockup mobilemockdashboard ${stepTwoCompleted ? 'mobilemockupduringstepthree' : ''} ${stepThreeCompleted ? 'mobilemockupduringstepfour' : ''} `}>
                <div className="google_interface google_interface_mobile">
                    <div className="header_area_mobile">
                        <div className="header_ser_mobile">
                            <div className="serach_wapper">
                                <div className="google_logo_mobile">
                                    <img src="images/Google_2015_logo2.png" alt="" height="40px" />
                                </div>
                                <div className="gmail gmailmobile">
                                    <img src="images/smalllogo.png" alt="" />
                                </div>
                                <div className="google_serach google_serach_mobile">
                                    <div className="ser">

                                        <button href="#" className="goog_btn serch_ico serch_icomobile">
                                            <img src="images/Vector_search_icon.svg.png" alt="" />
                                        </button>

                                        <input
  className={`google_serach_input google_serach_input_mobile p-0 ${mockupLoading ? 'loading-animation' : ''}`}
  type="text"
  value={mockup.url ? formatUrl(mockup.url) : "brandname"}
  readOnly
/>
                                        <div className="google-search-close me-2">
                                            <svg
                                                focusable="false"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                            >
                                                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="end_area">
                                        <button href="#" className="goog_btn mic_mobile">
                                            <img src="images/Google_mic.svg" alt="" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="right ">
                                {/* <div className="search_labs">
                                             <img src="images/serachlabs.png" alt="" width="25px" />
                                          </div> */}

                            </div>
                        </div>
                        <div className="tabsmobile">
                            <div className="left_tabs_mobile">
                                <button href="#" className="tab_btn_mobile active">All</button>
                                <button href="#" className="tab_btn_mobile">Images</button>
                                <button href="#" className="tab_btn_mobile">Videos</button>
                                <button href="#" className="tab_btn_mobile">News</button>
                                <button href="#" className="tab_btn_mobile">Shopping</button>
                                <button href="#" className="tab_btn_mobile">Maps</button>
                                <button href="#" className="tab_btn_mobile">Books</button>
                                <button href="#" className="tab_btn_mobile d-none">
                                    <span>
                                        <i className="bx bx-dots-vertical-rounded"></i>
                                    </span>
                                    More
                                </button>
                            </div>
                            <div className="right_tabs d-none">
                                <button className="tab_btn ">Tool</button>
                            </div>
                        </div>
                    </div>
                    <div className="capsul_tab_mobile">
                        <button href="#" className="capsul_mobile">Free</button>
                        <button href="#" className="capsul_mobile">Reviews</button>
                        <button href="#" className="capsul_mobile">Open Now</button>
                        <button href="#" className="capsul_mobile">Clips</button>
                        <button href="#" className="capsul_mobile">Pricing</button>
                    </div>
                    <div className="google_serach_result_mobile">
                        <div className="sponsored-text2_mobile">
                            <span>Sponsored</span>
                        </div>
                        <div className="serach_item">
                            <div className="brand_logo_img brand_logo_img_mobile">
                                <img src="images/smalllogo.png" alt="" />
                            </div>
                            <div className="brand_info">
                                <div className="barnd_name_mobile">
                                    <h6 className={`m-0 ${mockupLoading ? 'loading-animation' : ''}`}>
                                        {mockup?.display_url
                                            ? mockup?.display_url
                                            : "yourwebsite.com"}
                                    </h6>
                                    <div className="link">
                                        <p className={`website_link_mobile ${mockupLoading ? 'loading-animation' : ''}`}>
                                            {mockup?.url
                                                ? mockup?.url
                                                : "#"}
                                        </p>
                                        <a href="#" onClick={(e) => { e.preventDefault();}} className="modal_dot_mobile">
                                            <i className="bx bx-dots-vertical-rounded"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="serach_result_main_mobile">
                            <a
                                href="#" className="" onClick={(e) => { e.preventDefault();}}
                            >
                                <h1 className={`${mockupLoading ? 'loading-animation' : ''}`}>
                                    {mockup?.headline
                                        ? mockup?.headline
                                        : " PPCC - Generate Google Ads - Try for Free "}
                                </h1>
                            </a>
                            {/* <span className="brand_name_highlisght">Munch</span> */}
                            <p className={`info_mobile ${mockupLoading ? 'loading-animation' : ''}`}>
                                {mockup?.description
                                    ? mockup?.description
                                    : "extracts the most engaging, trending and impactful clips from your long-form videosis centered around machine learning capabilities, designed to keep what's important."}...
                            </p>
                        </div>
                        {mockup?.sitelinks?.length >= 0
                            ? mockup?.sitelinks?.map((item, index) => (
                                <div className={`Ktlw8e_mobile ${index === Mocksite.length - 1 ? 'sitelinklast' : ''} ${mockupLoading ? 'loading-animation' : ''}`}>
                                    <div className="iCzAIb_mobile">
                                        <div className="d-flx mock-site-link">
                                            <a href="#" className="" onClick={(e) => { e.preventDefault();}}>{item.title}</a><span className="arrowsitelink">›</span>
                                            <div className="icon d-none-icon">
                                                <i className="bx bx-chevron-right"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                            : Mocksite?.map((item, index) => (
                                <div className={`Ktlw8e_mobile ${index === Mocksite.length - 1 ? 'sitelinklast' : ''} ${mockupLoading ? 'loading-animation' : ''}`}>
                                    <div className="iCzAIb_mobile">
                                        <div className="d-flx mock-site-link">
                                            <a className="" onclick="return false;">{item.site_text}</a>
                                            <div className="icon d-none-icon">
                                                <i className="bx bx-chevron-right"></i>
                                            </div>
                                        </div>
                                        <p className="info m-0">{item.sitePara}</p>
                                    </div>
                                </div>
                            ))}
                    </div>


                    {/* SECOND SEARCH RESULT */}
                    <div className="google_serach_result_mobile google_serach_result_mobile2">

                        <div className="serach_item">
                            <div className="brand_logo_img brand_logo_img_mobile">
                                <img src="images/smalllogo.png" alt="" />
                            </div>
                            <div className="brand_info">
                                <div className="barnd_name_mobile">
                                    <h6 className={`m-0 ${mockupLoading ? 'loading-animation' : ''}`}>
                                        {mockup?.display_url
                                            ? mockup?.display_url
                                            : "ppcc.ai"}
                                    </h6>
                                    <div className="link">
                                        <p className={`website_link_mobile ${mockupLoading ? 'loading-animation' : ''}`}>
                                            {mockup?.url
                                                ? mockup?.url
                                                : "# "}
                                        </p>
                                        <a href="#" onClick={(e) => { e.preventDefault();}} className="modal_dot_mobile">
                                            <i className="bx bx-dots-vertical-rounded"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="serach_result_main_mobile">
                            <a
                                href="#" className="" onClick={(e) => { e.preventDefault();}}
                            >
                                <h1 className={`${mockupLoading ? 'loading-animation' : ''}`}>
                                    {mockup?.headline
                                        ? mockup?.headline
                                        : " PPCC AI - Generate Google Ads - Try for Free "}
                                </h1>
                            </a>
                            {/* <span className="brand_name_highlisght">Munch</span> */}
                            <p className={`info_mobile info_mobile2 ${mockupLoading ? 'loading-animation' : ''}`}>
                                {mockup?.description
                                    ? mockup?.description
                                    : "extracts the most engaging, trending and impactful clips from your long-form videosis centered around machine learning capabilities, designed to keep what's important."}...
                            </p>
                            <div className={`snippetdivmobile ${mockupLoading ? 'loading-animation' : ''}`}><a href="#"><span className="snippetmobile">Your</span></a><a href="#"><span className="snippetmobile">Callouts</span></a><a href="#"><span className="snippetmobile">Here</span></a><a href="#"><span className="snippetmobile">Try and See for Free</span></a></div>
                        </div>


                    </div>



                </div>
            </div>
            </div>
            </div>
        </div>
        <div className="downloadbuttondiv text-center">
        <span className="creditcostsdashboard creditcostsdashboardfirst">
          Current cost: 250 credits.
        </span>
        
        <button className="btn coolbtnbecuasecool" onClick={handleExtract}>
          <span className={`${extractLoading ? 'loading-animation' : ''}`}>Download </span> <img className="imginbtn" src="/images/excelogo.png" alt="Excel Logo" />
        </button>
        <span className="creditcostsdashboard creditcostsdashboardsecond">
          Your balance: 1,000 credits.
        </span>
      </div>
      

      </div>
      <span className="bottomleftcorner mobiletoabove">
            <a className="rightmenutop" href="/legal">Legal</a> 
          <a className="rightmenutop" href="/">support@ppcc.ai</a>
          </span>
    </div>
 );
};

export default Creator;
