import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import Lightbox from "../Components/Lightbox";
import AnimatedSection from "../Components/AnimatedSection";
import { FaCheck } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";


const Pagescroll = () => {
    const navigate = useNavigate()
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxIndex, setLightboxIndex] = useState(0);
    const screenshots = [
        "/images/dashscreenn1.png",
        "/images/creatordashboardscreen.png",
        "/images/spotifyexcelscreen.png",
        "/images/googleadseditorscreen.png",
    ];

    const openLightbox = (index) => {
        setLightboxIndex(index);
        setLightboxOpen(true);
    };

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    const Wrapper = isMobile ? React.Fragment : AnimatedSection;

    return (
    
    <div className="keepscrollingdiv ascomponentscroll">
    

<h1 className="titleinsteps sectiontitlehome">Taking over</h1>
<div className="fourcolumns">
<Wrapper {...(!isMobile && { className: "nomobile" })}>
<div className="steponesect sectt">
<img className="imagesteps stepthreeimg" src="/images/searchlogo.png" alt="ppc ads ai generate"></img><br/>
<span>Step 1</span>
<h2 className="titleinsteps">Search</h2>
<p>"Do I really have to go through all of this?", the anwser is yes!</p>
</div>
</Wrapper>
<Wrapper {...(!isMobile && { className: "nomobile", delay: 0.1 })}>
<div className="steptwosect steptwosects sectt">
<img className="imagesteps stepthreeimg specialgpt" src="/images/gptlogo.png" alt="google ads editor"></img><br/>
<span>Step 2</span>
<h2 className="titleinsteps">Customize</h2>
<p>Client X wants search ads for page Y on keywords A, B and C.</p>
</div>
</Wrapper>
<Wrapper {...(!isMobile && { className: "nomobile", delay: 0.2 })}>
<div className="stepthreesect sectt">
<img className="imagesteps stepthreeimg" src="/images/excelogo.png" alt="google ads editor"></img><br/>
<span>Step 3</span>
<h2 className="titleinsteps">Organize</h2>
<p>Your import-ready campaign sheet is almost the end result!</p>
</div>
</Wrapper>
<Wrapper {...(!isMobile && { className: "nomobile", delay: 0.3 })}>
<div className="stepthreesect stepfoursect sectt">
<img className="imagesteps stepthreeimg" src="/images/googleeditor.png" alt="google ads editor"></img><br/>
<span>Step 4</span>
<h2 className="titleinsteps">Publish</h2>
<p>Not quite yet! Let's imagine launching PPC ads blind-folded.</p>
</div>
</Wrapper>
</div>
<img className="accoladeimg nomobile" src="/images/accolade.png"></img>
<div className="accoladediv nomobile">
<div><img className="ppcreplaces" src="/images/ppclogowhite.png"/></div>
<div><span className="casualsidetext">Don't believe us? Try it for yourself:</span><br/>
</div>
<div class="testbtnim"><a href="/creator" target="_blank" class="btn testbtnecht">Visit the App</a></div>
</div>

<h1 className="titleinsteps sectiontitlehome">Screenshots</h1>
<div className="fourcolumns">
<Wrapper {...(!isMobile && { className: "nomobile" })}>
<div className="steponesect sectt sectnopad" onClick={() => openLightbox(0)}>
<img
className=""
src="/images/dashscreenn1.png"
onClick={() => openLightbox(0)}
alt=""
/>
</div>
</Wrapper>
<Wrapper {...(!isMobile && { className: "nomobile", delay: 0.1 })}>
<div className="steptwosect steptwosects sectt sectnopad">
<img
className=""
src="/images/creatordashboardscreen.png"
onClick={() => openLightbox(1)}
alt=""
/>
</div>
</Wrapper>
<Wrapper {...(!isMobile && { className: "nomobile", delay: 0.2 })}>
<div className="stepthreesect sectt sectnopad">
<img
className=""
src="/images/spotifyexcelscreen.png"
onClick={() => openLightbox(2)}
alt=""
/>
</div>
</Wrapper>
<Wrapper {...(!isMobile && { className: "nomobile", delay: 0.3 })}>
<div className="stepthreesect stepfoursect sectt sectnopad">
<img
className=""
src="/images/googleadseditorscreen.png"
onClick={() => openLightbox(3)}
alt=""
/>
</div>
</Wrapper>
</div>


<h1 className="titleinsteps sectiontitlehome ffweg">Use cases</h1>
<div className="fourcolumns ffweg">
<Wrapper {...(!isMobile && { className: "nomobile" })}>
<div className="steponesect sectt">
<img className="imagesteps imagestepsother stepthreeimg" src="/images/mapslogo.png" alt="google ads editor"></img><br/>
<span>Local & brand</span>
<h3 className="titleinsteps">Campaigns</h3>
<p>Launch search ads for small business owners in seconds.</p>
</div>
</Wrapper>
<Wrapper {...(!isMobile && { className: "nomobile", delay: 0.1 })}>
<div className="steptwosect steptwosects sectt">
<img className="imagesteps specialgpt imagestepsother stepthreeimg" src="/images/chinalogo.png" alt="google ads editor"></img><br/>

<span>Campaigns that are</span>
<h3 className="titleinsteps">Multilingual</h3>

<p>Download assets in any pre-language for any page.</p>
</div>
</Wrapper>
<Wrapper {...(!isMobile && { className: "nomobile", delay: 0.2 })}>
<div className="stepthreesect sectt">
<img className="imagesteps imagestepsother stepthreeimg" src="/images/shopifylogo.png" alt="google ads editor"></img><br/>
<span>For clients in</span>
<h3 className="titleinsteps">E-Commerce</h3>
<p>Scale search engine advertising for large product catalogs.</p>
</div>
</Wrapper>
<Wrapper {...(!isMobile && { className: "nomobile", delay: 0.3 })}>
<div className="stepthreesect stepfoursect sectt">
<img className="imagesteps specialgpt imagestepsother stepthreeimg" src="/images/affiliatelogo.png" alt="google ads editor"></img><br/>
<span>Promoting</span>
<h3 className="titleinsteps">Affiliate links</h3>
<p>PPC traffic generation in bulk using affiliate links.</p>
</div>
</Wrapper>
</div>
<div className="ffweg">
<h1 className="titleinsteps sectiontitlehome">All Functionalities</h1>
<div className="fourcolumns">
<Wrapper {...(!isMobile && { className: "nomobile" })}>
<div className="steponesect sectt">
<img className="imagesteps specialgpt imagestepsother stepthreeimg" src="/images/inputurl.png" alt="google ads editor"></img><br/>
<span>Independent</span>
<h3 className="titleinsteps">Deep search</h3>
<p>Allow our AI to visit your website and scrape its content.</p>
</div>
</Wrapper>
<Wrapper {...(!isMobile && { className: "nomobile", delay: 0.1 })}>
<div className="steptwosect steptwosects sectt">
<img className="imagesteps specialgpt imagestepsother stepthreeimg" src="/images/editable.png" alt="google ads editor"></img><br/>
<span>100 percent</span>
<h3 className="titleinsteps">Editable prompts</h3>
<p>Customize the LLM instructions directly to enforce changes.</p>
</div>
</Wrapper>
<Wrapper {...(!isMobile && { className: "nomobile", delay: 0.2 })}>
<div className="stepthreesect sectt">
<img className="imagesteps specialgpt imagestepsother stepthreeimg" src="/images/presets.png" alt="google ads editor"></img><br/>
<span>Edit and save</span>
<h3 className="titleinsteps">Prompt presets</h3>
<p>Create reusable prompt sets for consistent copy generation.</p>
</div>
</Wrapper>
<Wrapper {...(!isMobile && { className: "nomobile", delay: 0.3 })}>
<div className="stepthreesect stepfoursect sectt">
<img className="imagesteps specialgpt imagestepsother stepthreeimg" src="/images/clientsdata.png" alt="google ads editor"></img><br/>
<span>Organize and</span>
<h3 className="titleinsteps">Save client data</h3>
<p>Store client templates for seamless organization.</p>
</div>
</Wrapper>
</div>

<div className="fourcolumnstwo">
<Wrapper {...(!isMobile && { className: "nomobile" })}>
<div className="steponesect sectt">
<img className="imagesteps specialgpt imagestepsother stepthreeimg" src="/images/limitschar.png" alt="google ads editor"></img><br/>
<span>Marks exceeded</span>
<h3 className="titleinsteps">Character limits</h3>
<p>Highlights character limits exceeded, if any.</p>
</div>
</Wrapper>
<Wrapper {...(!isMobile && { className: "nomobile", delay: 0.1 })}>
<div className="steptwosect steptwosects sectt">
<img className="imagesteps specialgpt imagestepsother stepthreeimg" src="/images/unique.png" alt="google ads editor"></img><br/>
<span>Ad component</span>
<h3 className="titleinsteps">Separation</h3>
<p>Selectively generate only the needed ad components.</p>
</div>
</Wrapper>
<Wrapper {...(!isMobile && { className: "nomobile", delay: 0.2 })}>
<div className="stepthreesect sectt">
<img className="imagesteps imagestepsother stepthreeimg" src="/images/shopifylogo.png" alt="google ads editor"></img><br/>
<span>Two-minute</span>
<h3 className="titleinsteps">Multi-URL campaigns</h3>
<p>Launch campaigns across multiple URLs.</p>
</div>
</Wrapper>
<Wrapper {...(!isMobile && { className: "nomobile", delay: 0.3 })}>
<div className="stepthreesect stepfoursect sectt">
<img className="imagesteps specialgpt imagestepsother stepthreeimg" src="/images/excelogo.png" alt="google ads editor"></img><br/>
<span>Easily download an</span>
<h3 className="titleinsteps">Importable sheet</h3>
<p>To publish, copy-paste to the Google Ads Editor.</p>
</div>
</Wrapper>
</div>

<div className="fourcolumnstwo fourcolumnstwothird">
<Wrapper {...(!isMobile && { className: "nomobile" })}>
<div className="steponesect sectt">
<img className="imagesteps specialgpt imagestepsother stepthreeimg" src="/images/chinalogo.png" alt="google ads editor"></img><br/>
<span>Select from</span>
<h3 className="titleinsteps">+100 languages</h3>
<p>Optionally set a Cialdini principle, and language.</p>
</div>
</Wrapper>
<Wrapper {...(!isMobile && { className: "nomobile", delay: 0.1 })}>
<div className="steptwosect steptwosects sectt">
<img className="imagesteps specialgpt imagestepsother stepthreeimg" src="/images/mrcial.png" alt="google ads editor"></img><br/>
<span>Integratable</span>
<h3 className="titleinsteps">Cialdini's principles</h3>
<p>Optionally select one of Cialdini's principles to consider.</p>
</div>
</Wrapper>
<Wrapper {...(!isMobile && { className: "nomobile", delay: 0.2 })}>
<div className="stepthreesect sectt">
<img className="imagesteps specialgpt imagestepsother stepthreeimg" src="/images/searchlogo.png" alt="google ads editor"></img><br/>
<span>Google search</span>
<h3 className="titleinsteps">Mockup generation</h3>
<p>Generate ad visualizations for your clients.</p>
</div>
</Wrapper>
<Wrapper {...(!isMobile && { className: "nomobile", delay: 0.3 })}>
<div className="stepthreesect stepfoursect sectt">
<img className="imagesteps specialgpt imagestepsother stepthreeimg" src="/images/hourglass.png" alt="google ads editor"></img><br/>
<span>Much more</span>
<h3 className="titleinsteps">Coming soon</h3>
<p>We are actively improving and adding functionalities.</p>
</div>
</Wrapper>
</div></div>

<h1 className="titleinsteps sectiontitlehome">Compare us</h1>
<div className="comparetablediv">
<table className="comparetable">
<tr className="comparetablerow rowfirsttable">
<td className="tablecd featurecd"><span className="scrollmetable">Scroll me →</span></td>
<td className="tablecd">
<img className="comparetableimage" src="/images/ppccaitool.png" />
</td>
<td className="tablecd">
<img className="comparetableimage" src="/images/ppcadtool.png" />
</td>
<td className="tablecd">
<img className="comparetableimage" src="/images/gpttool.png" />
</td>
<td className="tablecd">
<img className="comparetableimage" src="/images/anywordtool.png" />
</td>
<td className="tablecd">
<img className="comparetableimage" src="/images/adscaletool.png" />
</td>
<td className="tablecd">
<img className="comparetableimage" src="/images/promonavitool.png" />
</td>
<td className="tablecd">
<img className="comparetableimage" src="/images/optimyzrtool.png" />
</td>
</tr>

<tr className="comparetablerow rowfirsttable">
<td className="tablecd featurecd"><img className="iconintable"src="/images/linkicon.png"/><span className="textnexttotableicon">URL based AI generation</span></td>
<td className="tablecd"><FaCheck color="#fff" size={20}/></td> 
<td className="tablecd"></td> 
<td className="tablecd"></td> 
<td className="tablecd"></td> 
<td className="tablecd"></td> 
<td className="tablecd"></td> 
<td className="tablecd"></td> 
</tr>


<tr className="comparetablerow rowfirsttable">
<td className="tablecd featurecd"><img className="iconintable"src="/images/exporticon.png"/><span className="textnexttotableicon">Export for Google ads Editor</span></td>
<td className="tablecd"><FaCheck color="#fff" size={20}/></td> 
<td className="tablecd"></td>
<td className="tablecd"></td>
<td className="tablecd"></td>
<td className="tablecd"></td>
<td className="tablecd"></td>
<td className="tablecd"></td>
</tr>

<tr className="comparetablerow rowfirsttable">
<td className="tablecd featurecd"><img className="iconintable"src="/images/langicon.png"/><span className="textnexttotableicon">Multi-language support</span></td>
<td className="tablecd"><FaCheck color="#fff" size={20}/></td>
<td className="tablecd"></td>
<td className="tablecd"><FaCheck color="#fff" size={20}/></td>
<td className="tablecd"><FaCheck color="#fff" size={20}/></td>
<td className="tablecd"></td>  
<td className="tablecd"></td>
<td className="tablecd"></td>
</tr>

<tr className="comparetablerow rowfirsttable">
<td className="tablecd featurecd"><img className="iconintable"src="/images/prompticon.png"/><span className="textnexttotableicon">Prompt customization</span></td>
<td className="tablecd"><FaCheck color="#fff" size={20}/></td>
<td className="tablecd"></td>
<td className="tablecd"><FaCheck color="#fff" size={20}/></td>
<td className="tablecd"></td>
<td className="tablecd"></td>
<td className="tablecd"></td>
<td className="tablecd"></td>
</tr>

<tr className="comparetablerow rowfirsttable">
<td className="tablecd featurecd"><img className="iconintable"src="/images/dataicon.png"/><span className="textnexttotableicon">Save client data</span></td>
<td className="tablecd"><FaCheck color="#fff" size={20}/></td>
<td className="tablecd"><FaCheck color="#fff" size={20}/></td>
<td className="tablecd"></td>
<td className="tablecd"></td>
<td className="tablecd"></td>
<td className="tablecd"><FaCheck color="#fff" size={20}/></td>
<td className="tablecd"><FaCheck color="#fff" size={20}/></td>
</tr>

<tr className="comparetablerow rowfirsttable">
<td className="tablecd featurecd"><img className="iconintable"src="/images/limiticon.png"/><span className="textnexttotableicon">Character limit checking</span></td>
<td className="tablecd"><FaCheck color="#fff" size={20}/></td>
<td className="tablecd"><FaCheck color="#fff" size={20}/></td>
<td className="tablecd"></td>
<td className="tablecd"><FaCheck color="#fff" size={20}/></td>
<td className="tablecd"></td>
<td className="tablecd"></td>
<td className="tablecd"><FaCheck color="#fff" size={20}/></td>
</tr>

<tr className="comparetablerow rowfirsttable">
<td className="tablecd featurecd"><img className="iconintable"src="/images/visualicon.png"/><span className="textnexttotableicon">Visuals & ad mockups</span></td>
<td className="tablecd"><FaCheck color="#fff" size={20}/></td>
<td className="tablecd"><FaCheck color="#fff" size={20}/></td>
<td className="tablecd"></td>
<td className="tablecd"></td>
<td className="tablecd"></td>
<td className="tablecd"></td>
<td className="tablecd"></td>
</tr>

<tr className="comparetablerow rowfirsttable">
<td className="tablecd featurecd"><img className="iconintable"src="/images/multiicon.png"/><span className="textnexttotableicon">Multi-URL campaigns</span></td>
<td className="tablecd"><FaCheck color="#fff" size={20}/></td>
<td className="tablecd"><FaCheck color="#fff" size={20}/></td>
<td className="tablecd"></td>
<td className="tablecd"></td>
<td className="tablecd"><FaCheck color="#fff" size={20}/></td>
<td className="tablecd"><FaCheck color="#fff" size={20}/></td>
<td className="tablecd"><FaCheck color="#fff" size={20}/></td>
</tr>
</table>
</div>

<h1 className="titleinsteps sectiontitlehome">Options</h1>
<div class="threecolumns betasection betasectionps">
            
                <Wrapper {...(!isMobile && { className: "nomobile", delay: 0.1 })}>
                <div class="steptwosect steptwosects sectt clickable" onClick={() => navigate("/mockupgen")}>
                
                <span>Choice 1</span>
                    <h2 class="titleinsteps">Generate Mockup</h2>
                    <p>Generate a Google search ads mockup with AI. Click here.</p>
                </div>
                </Wrapper>
                <Wrapper {...(!isMobile && { className: "nomobile", delay: 0.2 })}>
                <div class="stepthreesect sectt clickable" onClick={() => navigate("/campaigns")}>
                    
                <span>Choice 2</span>
                    <h2 class="titleinsteps">Generate a Sheet</h2>
                    <p>Download an import-ready campaign for any page. Click here.</p>
                </div>
                </Wrapper>
                <Wrapper {...(!isMobile && { className: "nomobile", delay: 0.3 })}>
                <div class="stepthreesect stepfoursect sectt clickable" onClick={() => navigate("/creator")}>
                    
                <span>Choice 3</span>
                    <h2 class="titleinsteps">Visit the App</h2>
                    <p>Try the all-in-one tool in it's unrestricted glory. Click here.</p>
                </div>
                </Wrapper>
            </div>




<div className="ffweg betasection"><h1 className="titleinsteps sectiontitlehome">Unlock Beta Access</h1>
<div className="twocolumnsspecial">
<AnimatedSection>
<div className="steponesect sectt specialfirstcolumn">
<img className="productlogo" src="/images/ppclogowhite.png"/>
<div className="twosubimages">
<img
className="screenshotimg firsttwoscreens"
src="/images/dashboardscreenf.png"
onClick={() => openLightbox(0)}
alt=""
/>
<img
className="screenshotimg firsttwoscreens secondscreenshot"
src="/images/dashboardscreenf.png"
onClick={() => openLightbox(1)}
alt=""
/>
</div>
<div className="subimages">
<img
className="screenshotimg threescreens"
src="/images/dashboardscreenf.png"
onClick={() => openLightbox(2)}
alt=""
/>
<img
className="screenshotimg threescreens"
src="/images/dashboardscreenf.png"
onClick={() => openLightbox(3)}
alt=""
/>
<img
className="screenshotimg threescreens"
src="/images/dashboardscreenf.png"
onClick={() => openLightbox(4)}
alt=""
/>
</div>
<div className="thumbnailbtns">
<button className="btn btn-sq special-btn" onClick={() => openLightbox(0)}>See screenshots</button>
<button className="btn btn-sq special-btn">Play launch video</button>
</div>
</div>
</AnimatedSection>
<AnimatedSection delay={0.1}>

<div className="steponesect sectt specialsecondcolumn">
<h3 className="titleinsteps titleinoffer">The PPC Campaign Creation Tool</h3>
<div className="starssecond">
<img className="starsfirst"src="/images/starsfirst.png"></img>
<span className="firststartext">+243 monthly users</span>
</div>
<p className="othertext">
<br/>Create search campaigns in minutes (and replace $200-$3K in monthly costs).</p>

<span className="usualpricing">$79.99</span><span className=" pricingdiscount"> $27.99</span><span className="permonth"> / month</span>
<p className="offer-text"></p>
<button className="btn special-btn btn-sq getstartedbtn">Get Started</button>
<p className="guarantee-text">If you're not happy, we'll refund you — no questions asked.</p>
</div>

</AnimatedSection>
</div>
</div>
{lightboxOpen && (
  <Lightbox
    images={screenshots}
    currentIndex={lightboxIndex}
    onClose={() => setLightboxOpen(false)}
  />
)}
</div>

   );
};

export default Pagescroll;
