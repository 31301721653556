import React, { useState } from "react";
import { TbRosetteDiscountCheckFilled } from "react-icons/tb";
import { motion } from "framer-motion";
import { AuthFetch } from "../lib/auth";
import useAuth from "../hooks/useAuth";
import { useNavigate } from 'react-router-dom';
import { useUser } from "../contexts/userContext";

const daysLeft = () => {
   const today = new Date();
   const currentYear = today.getFullYear();
   const feb1 = today.getMonth() === 1 && today.getDate() > 1
       ? new Date(currentYear + 1, 1, 1) // February 1 of next year if today is past February
       : new Date(currentYear, 1, 1); // February 1 of the current year
   const diffInTime = feb1 - today; // Difference in milliseconds
   return Math.ceil(diffInTime / (1000 * 60 * 60 * 24)); // Convert to days
};

const PricingTableMobile = () => {
   const [loading, setLoading] = useState(false);
   const isAuthenticated = useAuth();
   const { userPlan } = useAuth();
   const navigate = useNavigate();
   const daysLeftUntilJan = daysLeft();

   const createCheckoutSession = async (priceId) => {
      setLoading(true);
      if (!isAuthenticated) {
         navigate('/login');
         return;
      }
      navigate('/freecredits');
  return;
      
      const { session } = await AuthFetch(`stripe/checkoutSession/${priceId}`, {
         method: 'GET',
      });
      window.location.href = session;
   };

   const animatedCornerProps = {
      initial: { left: "-10%", opacity: 1 },
      animate: { left: ["-10%", "90%"], opacity: [1, 1, 0] },
      transition: {
         duration: 4,
         ease: [0.2, 0.4, 0.8, 1],
         repeat: Infinity,
         times: [0, 0.7, 1],
      },
   };

   return (
      <div className="pricing-mob-main">
         <div className="pricing-table-title-block">
            <div className="title">
               <h5>No Monthly Subscriptions</h5>
               <div className="percentage">Flexible</div>
            </div>
            <p className="title-para p-16">Enjoy our pay as you go model, purchase additional credits <a href="/recharge" class="linknostyle">here</a>.</p>
         </div>

            <div className="plan-card-main">
            <motion.div
      className="gradient d-block"
      initial={{ left: "0%", opacity: 0 }}
      animate={{ left: ["0%", "70%"], opacity: [1, 0.5, 0.1] }}
      transition={{
         duration: 2.3,
         ease: [0.2, 0.4, 0.8, 1],
         repeat: Infinity,
         repeatDelay: 0.7,
         times: [0, 0.7, 1],
      }}
      style={{
         background: "linear-gradient(270deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 88.2461%)",
         height: "1px",
         position: "absolute",
         top: "-1px",
         width: "40px",
         zIndex: 1
      }}
   />
   <motion.div
      className="gradient-bottom d-block"
      initial={{ top: "0%", opacity: 0 }}
      animate={{ top: ["0%", "70%"], opacity: [1, 0.5, 0.1] }}
      transition={{
         duration: 2.3,


         ease: [0.2, 0.4, 0.8, 1],
         repeat: Infinity,
         repeatDelay: 0.7,
         times: [0, 0.7, 1],
      }}
      style={{
         background: "linear-gradient(360deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 88.2461%)",
         height: "40px",
         position: "absolute",
         right: "-1px",
         top: "-4px",
         width: "1px",
         zIndex: 1
      }}
   />
               <div className="card-title-main">
                  <div className="card-price-main">
                     <h4>Free</h4>
                     <span>/Month</span>
                  </div>
                  <button 
      onClick={() => createCheckoutSession(process.env.REACT_APP_PRICE_ID_2500)} 
      className={`choose-plan-btn ${userPlan === "free" ? "selectedbtn" : ""}`}>
      {!isAuthenticated ? "Create Account" : userPlan === "free" ? "Current" : "Downgrade"}
   </button>
               </div>
               <div className="price-table-row">
                  <h6>Monthly credits</h6>
                  <p>2,500 credits</p>
               </div>
               <div className="price-table-row">
                  <h6>Equivalent of</h6>
                  <p>10 campaigns</p>
               </div>
            </div>

         <div className="plan-card-main">
            <div className="card-title-main">
               <div className="card-price-main">
                  <h4>$49.99</h4>
                  <span>/Month</span>
               </div>
               <button 
   onClick={() => createCheckoutSession(process.env.REACT_APP_PRICE_ID_15000)}
   className={`choose-plan-btn ${userPlan === "pro" ? "selectedbtn" : ""}`}
>
   {!isAuthenticated ? "Choose this Plan" : userPlan === "pro" ? "Current" : userPlan === "free" ? "Upgrade" : "Downgrade"}
</button>
            </div>
            <div className="price-table-row">
               <h6>Monthly credits</h6>
               <p>15,000 credits</p>
            </div>
            <div className="price-table-row">
               <h6>Equivalent of</h6>
               <p>60 campaigns</p>
            </div>
            <div className="price-table-row">
               <div className="check-list d-flex align-items-center flex-row h-100 gap-3">
                  <TbRosetteDiscountCheckFilled color="#fff" size={20} />
                  <p>Extract campaign data to CSV</p>
               </div>
            </div>
            <div className="price-table-row">
               <div className="check-list d-flex align-items-center flex-row h-100 gap-3">
                  <TbRosetteDiscountCheckFilled color="#fff" size={20} />
                  <p>Customize instructions and language</p>
               </div>
            </div>
         </div>

         <div className="plan-card-main secondmain">
           <motion.div
      className="gradient d-block"
      initial={{ left: "0%", opacity: 0 }}
      animate={{ left: ["0%", "70%"], opacity: [1, 0.5, 0.1] }}
      transition={{
         duration: 2.5,
         ease: [0.2, 0.4, 0.8, 1],
         repeat: Infinity,
         repeatDelay: 1.7,
         times: [0, 0.7, 1],
      }}
      style={{
         background: "linear-gradient(270deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 88.2461%)",
         height: "1px",
         position: "absolute",
         top: "-1px",
         width: "40px",
         zIndex: 1
      }}
   />
   <motion.div
      className="gradient-bottom d-block"
      initial={{ top: "0%", opacity: 0 }}
      animate={{ top: ["0%", "70%"], opacity: [1, 0.5, 0.1] }}
      transition={{
         duration: 2.5,
         ease: [0.2, 0.4, 0.8, 1],
         repeat: Infinity,
         repeatDelay: 1.7,
         times: [0, 0.7, 1],
      }}
      style={{
         background: "linear-gradient(360deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 88.2461%)",
         height: "40px",
         position: "absolute",
         right: "-1px",
         top: "-4px",
         width: "1px",
         zIndex: 1
      }}
   />
            <div className="card-title-main">
               <div className="card-price-main">
                  <h4>$179.99</h4>
                  <span>/Month</span>
               </div>
               <button 
                     onClick={() => createCheckoutSession(process.env.REACT_APP_PRICE_ID_45000)} 
                     className={`choose-plan-btn w-100 ${userPlan === "advanced" ? "selectedbtn" : ""}`}>
                     {!isAuthenticated ? "Choose this Plan" : userPlan === "advanced" ? "Current" : "Upgrade"}
                  </button>
            </div>
            <div className="price-table-row">
               <h6>Monthly credits</h6>
               <p>45,000 credits</p>
            </div>
            <div className="price-table-row">
               <h6>Equivalent of</h6>
               <p>180 campaigns</p>
            </div>
            <div className="price-table-row">
               <div className="check-list d-flex align-items-center flex-row h-100 gap-3">
                  <TbRosetteDiscountCheckFilled color="#fff" size={20} />
                  <p>Extract campaign data to CSV</p>
               </div>
            </div>
            <div className="price-table-row">
               <div className="check-list d-flex align-items-center flex-row h-100 gap-3">
                  <TbRosetteDiscountCheckFilled color="#fff" size={20} />
                  <p>Customize instructions and language</p>
               </div>
            </div>
         </div>
      </div>
   );
};

export default PricingTableMobile;
